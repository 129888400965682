import { RRule } from 'rrule'

const randomRruleBuilders = {
  buildRRule(schedule) {
    // grab data pieces from all schedule types

    const freq = RRule.DAILY
    let byweekday

    // check for byweekday
    if (schedule.weekdays) {
      byweekday = []
      if (schedule.weekdays.includes('M')) {
        byweekday.push(RRule.MO)
      }
      if (schedule.weekdays.includes('Tu')) {
        byweekday.push(RRule.TU)
      }
      if (schedule.weekdays.includes('W')) {
        byweekday.push(RRule.WE)
      }
      if (schedule.weekdays.includes('Th')) {
        byweekday.push(RRule.TH)
      }
      if (schedule.weekdays.includes('F')) {
        byweekday.push(RRule.FR)
      }
      if (schedule.weekdays.includes('Sa')) {
        byweekday.push(RRule.SA)
      }
      if (schedule.weekdays.includes('Su')) {
        byweekday.push(RRule.SU)
      }
    }

    const rule = new RRule({
      freq: byweekday.length > 0 ? RRule.WEEKLY : freq,
      byweekday,
    })
    return rule
  },
  rruleToText(schedule) {
    const rrule = this.buildRRule(schedule)
    if (rrule) {
      return rrule.toText()
    }
    return null
  },
  rruleToString(schedule) {
    const rrule = this.buildRRule(schedule)
    if (rrule) {
      return rrule.toString()
    }
    return null
  },

  rruleStringToText(rruleString) {
    // check for Does Not Repeat default format
    if (rruleString.split('RRULE:').pop() === 'FREQ=DAILY;COUNT=1') {
      return 'Does Not Repeat'
    }
    const rrule = RRule.fromString(rruleString)
    return rrule.toText()
  },
}

export default randomRruleBuilders
