import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

// Components
import { Box } from '../Box'
import { PopulatedTable } from '../PopulatedTable'
import { Text } from '../Text'

// Utils, Services & Messages
import messages from './LocationsTable.messages'
import colors from '../../utils/colors'
import rruleParser from '../../utils/rruleParser'

/**
 * LocationsTable
 *
 * This component takes in an array of locations and converts each element
 * into the required format before passing to the reusable PopulatedTable
 *
 * Input format matches store/api (full location object)
 *
 * For custom client locations (when global param is not passed in) this
 * component will convert the input format into the format needed by the
 * sidebar (so that the sidebar can populate correctly on edit) as well
 * as pass the name, address, and type to the table
 *
 * This component is used for both custom and global client zone tables in
 * AddCLientZone and VefiryClient pages.
 * - editable prop used to make names clickable
 * - global prop signifies global zone instead of custom
 *
 */
const LocationsTable = ({
  editable = false,
  global = false,
  locations,
  editInSidebar,
  cellSize,
}) => {
  const tableHeaderData = [
    messages.tableHeaderOne,
    messages.tableHeaderTwo,
    messages.tableHeaderThree,
  ]
  const bodyData = []

  const getLocationNameLink = location =>
    editable ? (
      <Box
        onClick={() => {
          editInSidebar(location)
        }}
        style={{ color: colors.primary, cursor: 'pointer', textDecoration: 'underline' }}
      >
        {location.name}
      </Box>
    ) : (
      <Box>{location.name}</Box>
    )

  // determine which zone "icon" should be displayed in summary table
  const getTypeIcon = exclusionType => {
    let color
    let message
    switch (exclusionType) {
      case 'inclusion':
        color = 'focus'
        message = <FormattedMessage {...messages.tableTypeInclustion} />
        break
      case 'exclusion':
        color = 'status-error'
        message = <FormattedMessage {...messages.tableTypeExclustion} />
        break
      case 'sobriety_bac':
        color = 'status-error'
        message = <FormattedMessage {...messages.tableTypeSobriety} />
        break
      default:
        color = 'status-unknown'
        message = <FormattedMessage {...messages.tableTypeNone} />
    }
    return (
      <Box
        pad="xsmall"
        background={color}
        justify="center"
        align="center"
        width={{ max: '150px', min: '100px' }}
      >
        <Text size="xsmall" color="white">
          {message}
        </Text>
      </Box>
    )
  }

  if (global) {
    locations.forEach(globalZone => {
      // format and add to body data
      bodyData.push({
        name: getLocationNameLink(globalZone),
        address: globalZone.formatted_address,
        zoneType: getTypeIcon(globalZone.type),
      })
    })
  } else {
    locations.forEach(location => {
      // convert into format needed by sidebar
      // sidebarFormatVCheck will be passed to editInSidebar function
      let parsedFields
      let endTimeMoment
      if (location.schedule) {
        parsedFields = rruleParser.parseRRuleToVCheckFields(location.schedule)
        endTimeMoment = moment(parsedFields.startTime, 'HH:mm').add(location.duration, 's')
      }
      const sidebarFormatLocation = {
        ...location,

        // add vcheck scheduler fields if neeeded
        startDate: parsedFields ? parsedFields.startDate : null,
        startTime: parsedFields ? parsedFields.startTime : null,
        endTime: parsedFields ? endTimeMoment.format('HH:mm') : null,
        repeat: parsedFields ? parsedFields.repeat : null,
        repeatEvery: parsedFields ? parsedFields.repeatEvery : null,
        frequency: parsedFields ? parsedFields.frequency : null,
        endsOn: parsedFields ? parsedFields.endsOn : null,
        endDate: parsedFields ? parsedFields.endDate : null,
        occurrences: parsedFields ? parsedFields.occurrences : null,
        weekdays: parsedFields ? parsedFields.weekdays : null,
      }

      // format and add to body data
      bodyData.push({
        name: getLocationNameLink(sidebarFormatLocation),
        address: location.formatted_address,
        zoneType: getTypeIcon(location.type),
      })
    })
  }
  return (
    <PopulatedTable
      headerData={tableHeaderData}
      bodyData={bodyData}
      cellSize={cellSize}
    ></PopulatedTable>
  )
}

LocationsTable.propTypes = {
  editable: PropTypes.bool,
  global: PropTypes.bool,
  editInSidebar: PropTypes.func,
  locations: PropTypes.array,
  cellSize: PropTypes.string,
}

export default LocationsTable
