import React, { useState, useEffect } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

// Components
import { Form, FormField } from 'components/Form'

import { MaskedInput } from 'components/MaskedInput'
import { Box } from '../Box'

import { Select } from '../Select'

// Styles
import colors from '../../utils/colors'

// Mins value from json
import time from './Time.json'

// Utils, Services & Messages
import messages from './BacRandomScheduler.messages'

const BacRandomScheduler = ({ intl, state, updateRandomState, validationError }) => {
  const weekdays = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su']
  const [checkedWeekdays, setCheckedWeekdays] = useState(state.weekdays || [])
  const [randomStartValue, setRandomStartValue] = useState(
    state.rStartTime ? moment(state.rStartTime, 'HH:mm:ss').format('hh:mm A') : '08:00 AM',
  )
  const [randomEndValue, setRandomEndValue] = useState(
    state.rEndTime ? moment(state.rEndTime, 'HH:mm:ss').format('hh:mm A') : '08:00 AM',
  )

  useEffect(() => {
    updateRandomState({ fieldName: 'weekdays', data: checkedWeekdays })
  }, [checkedWeekdays])

  const onCheck = async value => {
    if (!checkedWeekdays.includes(value)) {
      setCheckedWeekdays([...checkedWeekdays, value])
    } else {
      setCheckedWeekdays(checkedWeekdays.filter(item => item !== value))
    }
  }
  return (
    <>
      <Form validate="blur">
        <Box direction="row-responsive" gap="small">
          <FormField
            name="random_frequency"
            id="random_frequency"
            label={intl.formatMessage(messages.rFreqLabel)}
          >
            <Select
              plain
              size="small"
              name="random_frequency"
              id="random_frequency"
              options={[
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
              ]}
              value={state.rFrequency}
              onChange={event => {
                updateRandomState({ fieldName: 'rFrequency', data: event.option })
              }}
              style={{ maxWidth: '100px' }}
            ></Select>
          </FormField>

          <FormField
            name="random_start"
            id="random_start"
            step={1800} // 30 minutes in seconds
            label={intl.formatMessage(messages.rStartLabel)}
            value={{ value: moment(state.rStartTime, 'HH:mm:ss').format('hh:mm A') }}
            onChange={e => {
              updateRandomState({
                fieldName: 'rStartTime',
                data: moment(e.target.value, 'hh:mm A').format('HH:mm:ss'),
              })
            }}
          >
            <MaskedInput
              plain
              name="random_start"
              id="random_start"
              mask={[
                {
                  length: [1, 2],
                  options: time.hours,

                  regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                  placeholder: 'HH',
                },
                { fixed: ':' },
                {
                  length: [1, 2],
                  options: time.mins,
                  regexp: /^0?[1-9]$|^[0-5][0-9]$|^[0-9]$/,
                  placeholder: 'MM',
                },
                { fixed: ' ' },
                {
                  length: 2,
                  options: ['AM', 'PM'],
                  regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                  placeholder: 'AM/PM',
                },
              ]}
              value={randomStartValue}
              onChange={event => setRandomStartValue(event.target.value)}
            />
          </FormField>

          <FormField
            name="random_end"
            id="random_end"
            label={intl.formatMessage(messages.rEndLabel)}
            value={{ value: moment(state.rEndTime, 'HH:mm:ss').format('hh:mm A') }}
            onChange={e => {
              updateRandomState({
                fieldName: 'rEndTime',
                data: moment(e.target.value, 'hh:mm A').format('HH:mm:ss'),
              })
            }}
            validate={[
              () => {
                const startTime = moment(state.rStartTime, 'HH:mm:ss')
                const endTime = moment(state.rEndTime, 'HH:mm:ss')
                const diffTime = (startTime - endTime) / 60000
                const minutes = diffTime % 60
                let hours = (diffTime - minutes) / 60
                let isValidationError = false
                isValidationError = !!(
                  startTime < endTime &&
                  endTime.diff(moment(startTime), 'minutes') < 60 * state.rFrequency
                )
                hours = Math.abs(hours) - 24
                if (!isValidationError)
                  isValidationError = !!(
                    startTime > endTime && Math.abs(hours) < 1 * state.rFrequency
                  )
                if (isValidationError) {
                  validationError(true)
                  return '1 hour window required for each VCheck'
                }
                validationError(false)
                return undefined
              },
            ]}
          >
            <MaskedInput
              plain
              name="random_end"
              id="random_end"
              mask={[
                {
                  length: [1, 2],
                  options: time.hours,
                  regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                  placeholder: 'HH',
                },
                { fixed: ':' },
                {
                  length: [1, 2],
                  options: time.mins,
                  regexp: /^0?[1-9]$|^[0-5][0-9]$|^[0-9]$/,
                  placeholder: 'MM',
                },
                { fixed: ' ' },
                {
                  length: 2,
                  options: ['AM', 'PM'],
                  regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                  placeholder: 'AM/PM',
                },
              ]}
              value={randomEndValue}
              onChange={event => setRandomEndValue(event.target.value)}
            />
          </FormField>
        </Box>
      </Form>
      <Box direction="row" margin={{ top: 'small' }}>
        <Box direction="row">
          {weekdays.map(day => (
            <WeekdaySelect
              className={checkedWeekdays.includes(day) ? 'checked' : 'unchecked'}
              type="button"
              data-testid="weekday"
              key={day}
              onClick={() => onCheck(day)}
            >
              {day}
            </WeekdaySelect>
          ))}
        </Box>
      </Box>
    </>
  )
}

BacRandomScheduler.propTypes = {
  intl: intlShape.isRequired,
  state: PropTypes.any,
  updateRandomState: PropTypes.func,
  validationError: PropTypes.func,
}

const WeekdaySelect = styled.button`
  background-color: transparent;
  border-radius: 5px;
  border: solid 2px ${colors.secondary};
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  margin-right: 5px;
  padding: 5px;
  width: 30px;
  &.checked {
    background-color: ${colors.secondary};
    color: white;
  }
`

export default injectIntl(BacRandomScheduler)
