import { defineMessages } from 'react-intl'

export const scope = 'app.containers.AddClientBacSchedule'

/*
 * AddClientBacSchedule Messages
 *
 * This contains all the text for the AddClientBacSchedule container.
 */
export default defineMessages({
  // main page
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Client Data...',
  },
  bacRandomCheckHeader: {
    id: `${scope}.bacRandomCheckHeader`,
    defaultMessage: 'Random BrAC Checks',
  },
  bacRandomCheckDescription: {
    id: `${scope}.bacRandomCheckDescription`,
    defaultMessage:
      'Set one or multiple random BrAC Check windows per day and even choose which days of the week a random BrAC Check window should be apply.',
  },
  rFreqLabel: {
    id: `${scope}.rFreqLabel`,
    defaultMessage: 'Frequency',
  },
  rStartLabel: {
    id: `${scope}.rStartLabel`,
    defaultMessage: 'Start Time',
  },
  rEndLabel: {
    id: `${scope}.rEndLabel`,
    defaultMessage: 'End Time',
  },

  bacScheduledCheckHeader: {
    id: `${scope}.bacScheduledCheckHeader`,
    defaultMessage: 'Scheduled BrAC Checks',
  },
  bacScheduledCheckDescription: {
    id: `${scope}.bacScheduledCheckDescription`,
    defaultMessage:
      'Use the same format here for schedule BrAC Checks as is currently in use for creating scheduled BrAC Checks.',
  },
  bacAddScheduleButtonLabel: {
    id: `${scope}.bacAddScheduleButtonLabel`,
    defaultMessage: 'Add Schedule BrAC Check',
  },
  bacAddRandomButtonLabel: {
    id: `${scope}.bacAddRandomButtonLabel`,
    defaultMessage: 'Add Random BrAC Check',
  },
  // side bar
  vcheckNameHeader: {
    id: `${scope}.vcheckNameHeader`,
    defaultMessage: 'BrAC Check Details',
  },
  vcheckNameLabel: {
    id: `${scope}.vcheckNameLabel`,
    defaultMessage: 'Name',
  },
  scheduleHeader: {
    id: `${scope}.scheduleHeader`,
    defaultMessage: 'Schedule',
  },
  scheduleDetails: {
    id: `${scope}.scheduleDetails`,
    defaultMessage: `Here you can schedule a BrAC Check to occur once, or repeat. Clients have a 15 minute window to complete BrAC Checks starting from the Begin time. The start date for a BrAc Check must be after the Client's monitoring start date.`,
  },
  backButtonLabel: {
    id: `${scope}.backButtonLabel`,
    defaultMessage: 'Back',
  },
  continueButtonLabel: {
    id: `${scope}.continueButtonLabel`,
    defaultMessage: 'Save & Continue',
  },
  closeButtonLabel: {
    id: `${scope}.closeButtonLabel`,
    defaultMessage: 'Save & Close',
  },
  scheduleSidebarHeaderAdd: {
    id: `${scope}.scheduleSidebarHeaderAdd`,
    defaultMessage: 'Add Scheduled BrAC Check',
  },
  scheduleSidebarHeaderEdit: {
    id: `${scope}.scheduleSidebarHeaderEdit`,
    defaultMessage: 'Edit Scheduled BrAC Check',
  },
  randomSidebarHeaderAdd: {
    id: `${scope}.scheduleSidebarHeaderAdd`,
    defaultMessage: 'Add Random BrAC Check',
  },
  randomSidebarHeaderEdit: {
    id: `${scope}.scheduleSidebarHeaderEdit`,
    defaultMessage: 'Edit Random BrAC Check',
  },
  minutesMessage: {
    id: `${scope}.minutesMessage`,
    defaultMessage: 'MINUTES',
  },
  enableBacSchedule: {
    id: `${scope}.enableBacSchedule`,
    defaultMessage: 'Enable BrAC Check Schedule',
  },
  bacCheckInWindow: {
    id: `${scope}.bacCheckInWindow`,
    defaultMessage: 'BrAC Check-In Window',
  },
  bacCheckThreshold: {
    id: `${scope}.bacCheckThreshold`,
    defaultMessage: 'BrAC Check Threshold',
  },
  bacCheckMinutesDescription: {
    id: `${scope}.bacCheckMinutesDescription`,
    defaultMessage:
      'Number of minutes allotted for the client to perform a BrAC Check before it is considered missed.',
  },
  bacCheckWeekdayDescription: {
    id: `${scope}.bacCheckWeekdayDescription`,
    defaultMessage:
      'Threshold of acceptance BrAC. A BrAC higher than the threshold can trigger an agent alert and will initiate a series of two (2) follow-up BrAC Check in 20 min interval.',
  },
})
