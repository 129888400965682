/* eslint-disable react/prop-types */
import React from 'react'

// Components
import { Box } from '../Box'
import { Text } from '../Text'

// Styles
import { StyledFormField } from './FormField.styles'

// Helpers
import { doc } from './Form.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 * FormField
 *
 * - If the field is marked as `required`, add an asterisk (*) to its label
 */
const createFormField = props => {
  const { label, required, value, children, disableAstreik, ...rest } = props
  return (
    <StyledFormField
      label={
        required ? (
          <Box direction="row">
            <Text>{label}</Text>
            <Text color="status-error" margin={{ left: 'xsmall' }}>
              {disableAstreik ? `` : '*'}
            </Text>
          </Box>
        ) : (
          label
        )
      }
      required={required}
      {...value}
      {...rest}
    >
      {children}
    </StyledFormField>
  )
}

export default createWithDoc({
  docFunction: doc,
  component: createFormField,
})
