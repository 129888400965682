/* eslint-disable react/prop-types */
import React from 'react'
import { FormUp, RadialSelected } from 'grommet-icons'

// Components
import { Box } from '../Box'
import { Button } from '../Button'
import { Text } from '../Text'

// Utils
import colors from '../../utils/colors'

export const InfoBox = ({ timestamp, width = '140px' }) => (
  <Box
    align="center"
    background="focus"
    margin={{ top: '15px', left: '-48px' }}
    style={{ background: colors.purple, height: '20px', width, maxWidth: width }}
  >
    <Text color="white" size="xsmall" weight="bold">
      {timestamp}
    </Text>
  </Box>
)

export const Cluster = ({ value, ...rest }) => (
  <Box align="center" pad="medium">
    <Button
      color="accent-4"
      label={
        <Text size="xsmall" weight="bold" color="white">
          {value}
        </Text>
      }
      size="small"
      style={{
        left: -10,
        margin: 0,
        padding: 0,
        position: 'absolute',
        top: -10,
        width: `${24 + value.length * 3}px`,
      }}
      {...rest}
    />
  </Box>
)

export const MovingPin = ({ id, heading, over, setOver, timestamp, ...rest }) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
    <Button
      onMouseOut={() => setOver(null)}
      onMouseOver={() => setOver(id)}
      plain
      primary={false}
      style={{
        background: colors.purple,
        borderRadius: 10,
        fontSize: 11,
        height: 20,
        left: -10,
        margin: 0,
        position: 'absolute',
        top: -10,
        transform: `rotate(${heading}deg)`,
        width: 20,
      }}
    >
      <FormUp color="white" size="20px" style={{ height: 20, width: 20 }} {...rest} />
    </Button>

    {over === id && <InfoBox timestamp={timestamp} />}
  </>
)
export const Pin = ({ id, over, setOver, timestamp, status, resourcetype, ...rest }) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
    <Button
      onMouseOut={() => setOver(null)}
      onMouseOver={() => setOver(id)}
      plain
      primary={false}
      style={{ left: -10, margin: 0, position: 'absolute', top: -10 }}
    >
      {/* FIXME: Refactor the svg to a resuable component */}
      <Box direction="row">
        {status !== 'missed' && resourcetype === 'VCheckEvent' && (
          <Box style={{ position: 'absolute' }}>
            <svg fill="none" viewBox="0 0 18 18" height="25" width="25">
              <circle fill="#00B907" r="9" cy="9" cx="9"></circle>
              <path
                fill="white"
                d="M9 2L3 4V10C3 13.3141 5.68594 16 9 16C12.3141 16 15 13.3141 15 10V4L9 2ZM13.875 10C13.875 12.6922 11.6922 14.875 9 14.875C6.30781 14.875 4.125 12.6922 4.125 10V4.84375L9 3.125L13.875 4.84375V10Z"
              ></path>
              <path
                fill="white"
                d="M6.90978 8.42393C6.85764 8.37155 6.79568 8.32999 6.72743 8.30164C6.65919 8.27328 6.58602 8.25868 6.51212 8.25868C6.43822 8.25868 6.36505 8.27328 6.29681 8.30164C6.22857 8.32999 6.1666 8.37155 6.11446 8.42393C6.06209 8.47606 6.02053 8.53803 5.99217 8.60627C5.96382 8.67451 5.94922 8.74768 5.94922 8.82158C5.94922 8.89548 5.96382 8.96865 5.99217 9.0369C6.02053 9.10514 6.06209 9.1671 6.11446 9.21924L8.13635 11.2411L8.16916 11.2739C8.21847 11.3233 8.27705 11.3625 8.34153 11.3893C8.40601 11.416 8.47513 11.4298 8.54494 11.4298C8.61475 11.4298 8.68387 11.416 8.74835 11.3893C8.81283 11.3625 8.87141 11.3233 8.92072 11.2739L12.4129 7.78174C12.4623 7.73242 12.5015 7.67385 12.5283 7.60937C12.555 7.54489 12.5688 7.47577 12.5688 7.40596C12.5688 7.33615 12.555 7.26703 12.5283 7.20255C12.5015 7.13807 12.4623 7.07949 12.4129 7.03018L12.3692 6.98643C12.3199 6.93702 12.2613 6.89782 12.1968 6.87108C12.1323 6.84433 12.0632 6.83057 11.9934 6.83057C11.9236 6.83057 11.8545 6.84433 11.79 6.87108C11.7255 6.89782 11.6669 6.93702 11.6176 6.98643L8.54416 10.0583L6.90978 8.42393Z"
              ></path>
            </svg>
          </Box>
        )}

        {status !== 'missed' && resourcetype === 'BACCheckEvent' && (
          <Box style={{ position: 'absolute', marginTop: 2 }}>
            <svg fill="none" viewBox="0 0 18 18" height="25" width="25">
              <circle fill="#7360F2" r="9" cy="9" cx="9"></circle>
              <g
                fill="#FFFFFF"
                transform="translate(9 9) scale(0.002,-0.002) translate(-2500,-2450)"
              >
                <path d="M1085 5101 c-96 -43 -144 -125 -145 -243 l0 -78 -284 0 c-278 0 -284 0 -317 -23 -18 -12 -44 -38 -56 -56 -22 -33 -23 -41 -23 -266 0 -224 1 -233 23 -266 12 -19 42 -44 67 -56 43 -22 54 -23 317 -23 l273 0 0 -77 c0 -91 22 -145 78 -194 55 -49 108 -69 182 -69 78 0 131 22 183 74 46 45 63 78 71 139 l7 47 165 0 164 0 0 -752 c0 -1007 -4 -976 185 -1338 80 -151 75 -92 75 -957 0 -838 -2 -802 54 -876 14 -19 48 -46 75 -61 l49 -26 1109 2 1108 3 42 22 c22 12 55 39 72 60 62 75 61 61 61 898 0 468 4 783 10 815 7 35 27 80 60 130 88 138 135 269 166 467 16 99 17 212 15 1317 -2 992 -5 1216 -17 1248 -21 59 -61 104 -117 133 l-51 25 -1355 0 c-1534 0 -1395 7 -1478 -83 -33 -36 -44 -57 -54 -106 l-12 -61 -163 0 -164 0 0 30 c0 69 -71 168 -145 201 -55 25 -175 25 -230 0z m3547 -161 c21 -6 43 -21 50 -33 20 -34 12 -2355 -8 -2483 -56 -366 -320 -727 -662 -907 -223 -117 -402 -152 -744 -144 -235 5 -328 20 -478 77 -404 156 -693 505 -793 960 -20 93 -21 120 -24 1286 l-4 1191 21 26 21 27 567 3 c1611 7 2018 6 2054 -3z m-3373 -26 c21 -26 21 -37 21 -478 l0 -452 -29 -28 c-25 -24 -35 -27 -65 -22 -72 14 -71 7 -71 491 0 236 3 442 8 457 11 41 34 58 77 58 30 0 43 -6 59 -26z m531 -474 l0 -250 -165 0 -165 0 0 250 0 250 165 0 165 0 0 -250z m-850 -5 l0 -165 -250 0 -250 0 0 165 0 165 250 0 250 0 0 -165z m1358 -2886 c174 -156 420 -277 662 -327 127 -25 628 -25 750 1 246 53 464 160 665 328 l60 50 3 -676 c2 -740 3 -726 -56 -742 -15 -5 -496 -8 -1070 -8 l-1043 0 -19 25 c-19 24 -20 50 -20 713 0 378 3 687 6 687 3 0 31 -23 62 -51z" />
                <path d="M3145 4685 c-488 -91 -830 -442 -910 -935 -22 -137 -22 -1097 0 -1222 40 -234 152 -438 331 -608 199 -189 428 -287 697 -298 618 -24 1086 366 1171 976 22 157 23 1010 1 1152 -34 226 -132 432 -281 590 -157 166 -331 273 -529 326 -127 33 -353 42 -480 19z m420 -194 c176 -47 382 -177 494 -313 47 -58 111 -170 126 -225 l6 -23 -861 0 -862 0 7 28 c40 167 277 393 516 494 169 71 397 86 574 39z m584 -730 c52 -6 96 -13 98 -15 2 -2 10 -75 18 -162 16 -169 19 -342 9 -443 l-7 -61 -932 0 -933 0 -7 216 c-7 199 -3 339 11 422 l6 32 89 0 89 0 -10 -26 c-15 -38 -12 -87 6 -112 12 -17 25 -22 62 -22 44 0 49 3 107 65 79 85 140 109 262 103 46 -2 86 -7 89 -11 4 -4 -77 -90 -179 -192 -185 -184 -187 -187 -187 -229 0 -59 23 -78 86 -74 l48 3 80 106 c163 213 257 302 382 361 112 52 161 58 454 54 146 -3 307 -10 359 -15z m118 -1023 c-6 -190 -25 -273 -91 -413 -68 -143 -147 -234 -311 -360 -72 -55 -217 -121 -321 -146 -77 -18 -109 -21 -229 -16 -209 8 -337 47 -500 152 -184 119 -321 310 -374 518 -23 88 -41 245 -41 353 l0 74 936 0 937 0 -6 -162z" />
              </g>
            </svg>
          </Box>
        )}
        {resourcetype !== 'VCheckEvent' && resourcetype !== 'BACCheckEvent' && (
          <RadialSelected color="accent-4" size="25px" {...rest} />
        )}
      </Box>
    </Button>
    {over === id && <InfoBox timestamp={timestamp} />}
  </>
)
