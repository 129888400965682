import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

// Components
import { Box } from '../Box'
import { PopulatedTable } from '../PopulatedTable'

// Utils, Services & Messages
import messages from './ScheduledRandomBacTable.messages'
import colors from '../../utils/colors'
import randomRruleParser from '../../utils/randomRuleParser'
import randomRruleBuilders from '../../utils/randomRuleBuilder'

/**
 * ScheduledVCheckTable
 *
 * This component takes in an array of scheduled vchecks and converts it into
 * the required format before passing to reusable PopulatedTable component
 *
 * Input format will match store/api:
 * {
 *    name:
 *    schedule:
 *    start:
 *    stop:
 *    ...other fields not used (id, created, etc.)
 * }
 *
 * This component will convert the input format into the format needed by
 * the sidebar (so that the sidebar can populate correctly on edit) as well
 * as pass the name, dates, and schedule (in text form) fields to the table
 *
 */
const ScheduledRandomBacTable = ({ editable = false, bacRandomChecks, editInSidebar }) => {
  const tableHeaderData = [
    messages.tableHeaderOne,
    messages.tableHeaderTwo,
    messages.tableHeaderThree,
    messages.tableHeaderFour,
    messages.tableHeaderFive,
  ]
  const bodyData = []

  const getVCheckNameLink = vcheck =>
    editable ? (
      <Box
        onClick={() => {
          editInSidebar(vcheck)
        }}
        style={{ color: colors.primary, cursor: 'pointer', textDecoration: 'underline' }}
      >
        {vcheck.id}
      </Box>
    ) : (
      <Box>{vcheck.id}</Box>
    )

  bacRandomChecks.forEach(vcheck => {
    // convert into format needed by sidebar
    // sidebarFormatVCheck will be passed to editInSidebar function
    if (vcheck) {
      const parsedFields = randomRruleParser.parseRRuleToVCheckFields(vcheck.schedule || '')
      // console.log('parsedFileds', parsedFields)
      const sidebarFormatVCheck = {
        id: vcheck.id,
        start: vcheck.start,
        stop: vcheck.stop,
        frequency: vcheck.frequency,
        weekdays: parsedFields.weekdays || [],
      }

      // format and add to body data
      bodyData.push({
        id: getVCheckNameLink(sidebarFormatVCheck),
        start: moment(sidebarFormatVCheck.start, 'HH:mm').format('hh:mm A'),
        stop: moment(sidebarFormatVCheck.stop, 'HH:mm').format('hh:mm A'),
        frequency: sidebarFormatVCheck.frequency,
        schedule: randomRruleBuilders.rruleStringToText(vcheck.schedule),
      })
    }
  })

  return <PopulatedTable headerData={tableHeaderData} bodyData={bodyData}></PopulatedTable>
}

ScheduledRandomBacTable.propTypes = {
  editable: PropTypes.bool,
  editInSidebar: PropTypes.func,
  bacRandomChecks: PropTypes.array,
}

export default ScheduledRandomBacTable
