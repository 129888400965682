import { RRule } from 'rrule'

const randomRruleParser = {
  parseRRuleToVCheckFields(rruleString) {
    if (!rruleString) {
      return null
    }

    // object to return (sets all fields except endTime, which is handled by component)
    const vcheckFields = {
      frequency: 'DAILY', // Day(s), Week(s), Month(s)
      weekdays: [], // ['M', 'TU', 'W', 'TH', 'F', 'SA', 'SU']
    }

    const rrule = RRule.fromString(rruleString)
    const rruleObject = rrule.origOptions

    // if not Does Not Repeat (Daily or Custom), break down in to field values

    // weekdays ([M, Tu, W, Th, F, Sa, Su])
    vcheckFields.weekdays = this.calculateWeekdays(rruleObject.byweekday)

    return vcheckFields
  },

  calculateWeekdays(weekdayArray) {
    if (!weekdayArray) {
      return null
    }
    const weekdays = []
    weekdayArray.forEach(day => {
      if (day.weekday === 0) {
        weekdays.push('M')
      }
      if (day.weekday === 1) {
        weekdays.push('Tu')
      }
      if (day.weekday === 2) {
        weekdays.push('W')
      }
      if (day.weekday === 3) {
        weekdays.push('Th')
      }
      if (day.weekday === 4) {
        weekdays.push('F')
      }
      if (day.weekday === 5) {
        weekdays.push('Sa')
      }
      if (day.weekday === 6) {
        weekdays.push('Su')
      }
    })

    return weekdays
  },
}

export default randomRruleParser
