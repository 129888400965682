/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react'
import GoogleMapReact from 'google-map-react'
import moment from 'moment'
import useSupercluster from 'use-supercluster'
import { forEach } from 'lodash'

// Components
import { Cluster, MovingPin, Pin } from './ClientLocationsMap.components'

// Utils, Services & Messages
import colors from '../../utils/colors'
import formatters from '../../utils/formatters'
import { getCoordinateObject } from '../../utils/helpers'
import { generatePolylines } from '../../utils/polylineGenerator'

const DEFAULT_ZOOM = 10
const DEFAULT_CENTER = { lat: 39.7392, lng: -104.9903 } // Arbitrary location in Denver

const GEOFENCE_TYPE = {
  circle: 'circle',
  polygon: 'polygon',
  none: 'none',
}

const ClientLocationsMap = ({
  events = [],
  customCenter = null,
  clientTimezone = 'UTC',
  zones = { global: [], locations: [] },
}) => {
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(DEFAULT_ZOOM)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [over, setOver] = useState(null)
  const mapRef = useRef()
  const polylinesRef = useRef([])
  const zonesRef = useRef([])

  /**
   * When customCenter changes, pan to that point and zoom in to a reasonable
   * level to view the point instead of clusters
   */
  useEffect(() => {
    if (mapRef.current) {
      const filteredClusters = clusters.filter(cluster => cluster.properties.cluster)
      if (filteredClusters.length > 0) {
        onClusterClick(filteredClusters[0], customCenter)
      } else {
        if (zoom === 15) {
          mapRef.current.setZoom(15)
          setZoom(15)
        }
        mapRef.current.panTo(customCenter)
      }
    }
  }, [customCenter])

  // events.forEach(event => {
  //   if (event.resourcetype === 'VCheckEvent' && event.status === 'on-time') {
  //     console.log(event)
  //   }
  // }),
  /**
   * Render zones when the map has loaded or when zones change
   */
  useEffect(() => {
    if (mapRef.current) {
      // Clear existing zones
      forEach(zonesRef.current, currentZone => {
        currentZone.setMap(null)
      })

      // Reset zones references
      zonesRef.current = []

      // Add new zones - all global zones are zone of interests
      forEach(zones.global.concat(zones.locations), zone => {
        // Zones without a type are not synchronized to Firestore and are thus not used to trigger
        // events
        if (zone.type !== null) {
          const color =
            zone.type && zone.type === 'inclusion' ? colors.brightGreen : colors.brightRed
          let geofence = null
          if (zone.geofence_type === GEOFENCE_TYPE.circle) {
            geofence = new window.google.maps.Circle({
              strokeColor: color,
              strokeOpacity: 0.8,
              strokeWeight: 1,
              fillColor: color,
              fillOpacity: 0.6,
              center: getCoordinateObject(zone.point.coordinates),
              radius: zone.radius,
            })
          } else if (zone.geofence_type === GEOFENCE_TYPE.polygon) {
            const geofencePolygonCoordiantes = []
            const paths = zone.polygon.coordinates[0]
            paths.forEach(element => {
              geofencePolygonCoordiantes.push({ lat: element[1], lng: element[0] })
            })
            geofence = new window.google.maps.Polygon({
              paths: geofencePolygonCoordiantes,
              strokeColor: color,
              strokeOpacity: 0.8,
              strokeWeight: 1,
              fillColor: color,
              fillOpacity: 0.6,
            })
          }

          if (geofence) {
            // Render the circle
            geofence.setMap(mapRef.current)
            // Store the zone so it can be cleared later
            zonesRef.current.push(geofence)
          }
        }
      })
    }
  }, [mapLoaded, zones])

  const convertToClientTimezone = time => time.tz(clientTimezone).format('MM/DD/YY hh:mm:ss A')
  /**
   * Generate a supercluster compatible point from a VCheck event
   */

  const getClusterPoint = event => ({
    status: event.status,
    resourcetype: event.resourcetype ? event.resourcetype : '',
    type: 'Feature',
    properties: {
      cluster: false,
      eventId: event.document_id,
      category: event.resourcetype ? event.resourcetype : '',
      timestamp: event.created ? convertToClientTimezone(moment(event.created)) : 'Not Found',
      isMoving: event.data ? event.data.geolocationMeta.is_moving : false,
      heading: event.data ? event.data.geolocationMeta.coords.heading : null,
    },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(event.longitude), parseFloat(event.latitude)],
    },
  })

  const points = events.reduce((eventPoints, event) => {
    if (event.longitude) eventPoints.push(getClusterPoint(event))
    return eventPoints
  }, [])

  const vcheckPoints = points.filter(point => point.resourcetype === 'VCheckEvent')

  const bacCheckPoints = points.filter(point => point.resourcetype === 'BACCheckEvent')

  const clusterPoints = points.filter(point => point.resourcetype === 'Event')
  /**
   * Configure clusters and supercluster
   *
   * Note: the options here may need to be dynamic in the future as we get a
   * better idea of realistic data for client events
   */
  const { clusters, supercluster } = useSupercluster({
    points: clusterPoints,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: 20,
    },
  })

  /**
   * If we have at least one point available, center on the first one
   * Otherwise, use the default center
   */
  const updatedCenter =
    points.length > 0 ? getCoordinateObject(points[0].geometry.coordinates) : DEFAULT_CENTER

  /**
   * Handle clicking on a cluster by updating the zoom and panning to the new map center
   *
   * @param {object} cluster The cluster that has been clicked on
   * @param {object} point New point to center the map on
   */
  const onClusterClick = (cluster, point) => {
    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20)
    mapRef.current.setZoom(expansionZoom)
    mapRef.current.panTo(point)
    setZoom(expansionZoom)
  }

  /**
   * - Calculate polylines based on all available points and the current clusters.
   * - Generate polylines for each of the calculated lines and store their reference so they can be
   * removed later
   */
  const renderPolylines = () => {
    /*
     * Do not attempt to render polylines until Google Maps is loaded by `GoogleMapReact`
     * If the library is pre-loaded, this should never fire. If the library needs to be reloaded,
     * a re-render is triggered by updating state in `onGoogleApiLoaded`
     */
    if (window.google === undefined) {
      return null
    }

    const generatedPolylines = generatePolylines(points, clusters)

    // Clear existing polylines
    forEach(polylinesRef.current, currentPolyline => {
      currentPolyline.setMap(null)
    })

    // Reset polyline references
    polylinesRef.current = []

    // This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
    function calcCrow(lat1, lon1, lat2, lon2) {
      const R = 6371 // km
      const dLat = toRad(lat2 - lat1)
      const dLon = toRad(lon2 - lon1)
      const updatedLat1 = toRad(lat1)
      const updatedLat2 = toRad(lat2)

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(updatedLat1) * Math.cos(updatedLat2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c
      return d
    }

    // Converts numeric degrees to radians
    function toRad(Value) {
      return (Value * Math.PI) / 180
    }

    // Add new polylines
    generatedPolylines.forEach(line => {
      // Only render points further than a distance of 0.05
      const newLine = line.filter((point, index) => {
        if (index === 0) {
          return point
        }
        const firstPoint = line[index]
        const lastPoint = line[index - 1]
        const distance = calcCrow(firstPoint.lat, firstPoint.lng, lastPoint.lat, lastPoint.lng)
        if (distance > 0.05) {
          return point
        }
        return null
      })
      const polyline = new window.google.maps.Polyline({
        path: newLine,
        geodesic: true,
        strokeColor: colors.purple,
        strokeOpacity: 1.0,
        strokeWeight: 4,
      })
      // Render the polyline
      polyline.setMap(mapRef.current)

      // Store the polyline so it can be cleared later
      polylinesRef.current.push(polyline)
    })

    return null
  }

  /**
   * Generate an array of markers for each point type. Point types are one of:
   * - Cluster (denoted by `isCluster`)
   * - MovingPin (denoted by `isMoving` and heading not equal to -1. The heading value is not super
   * consistent, but these are often one-off movement points or the very first or very last movement
   * in a series of moving points. Since -1 really denotes not moving at all, this falls back to a
   * regular point)
   * - Pin (default)
   */
  const renderClusterPoints = () =>
    clusters.map(cluster => {
      const [lng, lat] = cluster.geometry.coordinates
      const { cluster: isCluster, point_count: pointCount } = cluster.properties
      if (isCluster) {
        return (
          <Cluster
            key={`cluster-${cluster.id}`}
            lat={lat}
            lng={lng}
            onClick={() => onClusterClick(cluster, { lat, lng })}
            value={formatters.formatClusterQty(pointCount)}
          />
        )
      }

      if (cluster.properties.isMoving && cluster.properties.heading !== -1) {
        return (
          <MovingPin
            id={cluster.properties.eventId}
            heading={cluster.properties.heading}
            timestamp={cluster.properties.timestamp}
            key={`event-${cluster.properties.eventId}`}
            lat={lat}
            lng={lng}
            over={over}
            setOver={setOver}
          />
        )
      }
      return (
        <Pin
          status={cluster.status}
          resourcetype={cluster.resourcetype}
          id={cluster.properties.eventId}
          timestamp={cluster.properties.timestamp}
          key={`event-${cluster.properties.eventId}`}
          lat={lat}
          lng={lng}
          over={over}
          setOver={setOver}
        />
      )
    })

  const renderVcheckPoints = () =>
    vcheckPoints.map(point => {
      const [lng, lat] = point.geometry.coordinates
      return (
        <Pin
          status={point.status}
          resourcetype={point.resourcetype}
          id={point.properties.eventId}
          timestamp={point.properties.timestamp}
          key={`event-${point.properties.eventId}`}
          lat={lat}
          lng={lng}
          over={over}
          setOver={setOver}
        />
      )
    })

  const renderBrACcheckPoints = () =>
    bacCheckPoints.map(point => {
      const [lng, lat] = point.geometry.coordinates
      return (
        <Pin
          status={point.status}
          resourcetype={point.resourcetype}
          id={point.properties.eventId}
          timestamp={point.properties.timestamp}
          key={`event-${point.properties.eventId}`}
          lat={lat}
          lng={lng}
          over={over}
          setOver={setOver}
        />
      )
    })

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API_KEY, language: 'en' }}
      defaultCenter={DEFAULT_CENTER}
      center={updatedCenter}
      defaultZoom={DEFAULT_ZOOM}
      onChange={({ zoom: newZoom, bounds: newBounds }) => {
        setZoom(newZoom)
        setBounds([newBounds.nw.lng, newBounds.se.lat, newBounds.se.lng, newBounds.nw.lat])
      }}
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map
        setMapLoaded(true)
      }}
      options={{ mapTypeControl: true, streetViewControl: true, scrollwheel: false }}
      yesIWantToUseGoogleMapApiInternals
    >
      {renderPolylines()}
      {renderClusterPoints()}
      {renderVcheckPoints()}
      {renderBrACcheckPoints()}
    </GoogleMapReact>
  )
}

export default ClientLocationsMap
