import React from 'react'
import { FormattedMessage } from 'react-intl'
import { orderBy, startCase, toLower } from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
// Components
import { Box } from '../Box'
import { TableHeader } from '../Table'
// Utils, Services & Messages
import messages from './MonitoringTable.messages'
import { getTablePaginationOptions } from '../../utils/helpers'
import { LoadingIndicator } from '../LoadingIndicator'

/**
 * MonitoringTable
 *
 *  Timezone Notes:
 *  - created datetime is displayed in client timezone because it is client-created
 *
 */
const MonitoringTable = ({
  loading,
  printable,
  clientEvents,
  clientTimezone,
  setCustomCenter,
  rowCount,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const orderedEvents = orderBy(
    clientEvents,
    [event => event.created || moment.utc(event.alert_date_time).toISOString()], // Use `created`, fallback to `updated`
    ['desc'],
  )

  const dataTableHeaders = [
    {
      selector: 'id',
      name: '',
      omit: true,
    },
    {
      selector: 'type',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'bacResult',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderSix} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
      maxWidth: '50px',
    },
    {
      selector: 'time',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTwo} />
        </StyledTableHeader>
      ),
      format: row => {
        if (row.time) {
          return moment(row.time)
            .tz(clientTimezone)
            .format('MM/DD/YY hh:mm:ss A z')
        }
        return 'Not Found'
      },
      sortable: false,
      style: { 'font-size': 14 },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'location',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderThree} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      wrap: true,
    },
    {
      selector: 'zoneName',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFour} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'zoneType',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFive} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'event',
      omit: true,
    },
  ]

  const getEventType = event => {
    if (event.resourcetype === 'Application Alert') {
      return <Box>{event.status}</Box>
    }

    if (event.type === 'no_activity_alert') {
      return 'No GPS Alert'
    }

    if (event.resourcetype === 'Event') {
      const type = startCase(toLower(event.type))
      const classifier = event.classifier ? `:${startCase(toLower(event.classifier))}` : ''
      const typeClassifier = `${type}${classifier}`
      if (type === 'Geo' || type === 'App') {
        return 'Location Trace'
      }
      return typeClassifier
    }

    if (event.resourcetype === 'VCheckEvent') {
      if (
        event.status === 'on-time' ||
        event.status === 'late' ||
        event.status === 'grouped' ||
        event.status === 'completed'
      )
        return (
          <Box direction="row" gap="xsmall" justify="center" alignContent="center">
            <Box justify="center" alignContent="center">
              {event.type} VCheck
            </Box>
            <svg
              width="30"
              height="25"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 1.875L3.75 5.625V16.875C3.75 23.0889 8.78613 28.125 15 28.125C21.2139 28.125 26.25 23.0889 26.25 16.875V5.625L15 1.875ZM24.1406 16.875C24.1406 21.9229 20.0479 26.0156 15 26.0156C9.95215 26.0156 5.85938 21.9229 5.85938 16.875V7.20703L15 3.98438L24.1406 7.20703V16.875Z"
                fill="#4C989D"
              />
              <path
                d="M11.0862 13.9192C10.9885 13.821 10.8723 13.7431 10.7443 13.6899C10.6164 13.6367 10.4792 13.6094 10.3406 13.6094C10.202 13.6094 10.0648 13.6367 9.93689 13.6899C9.80894 13.7431 9.69275 13.821 9.59499 13.9192C9.49679 14.0169 9.41887 14.1331 9.3657 14.2611C9.31253 14.389 9.28516 14.5262 9.28516 14.6648C9.28516 14.8034 9.31253 14.9406 9.3657 15.0685C9.41887 15.1965 9.49679 15.3126 9.59499 15.4104L13.386 19.2014L13.4475 19.2629C13.54 19.3556 13.6498 19.4291 13.7707 19.4792C13.8916 19.5294 14.0212 19.5552 14.1521 19.5552C14.283 19.5552 14.4126 19.5294 14.5335 19.4792C14.6544 19.4291 14.7643 19.3556 14.8567 19.2629L21.4046 12.7151C21.4972 12.6226 21.5707 12.5128 21.6209 12.3919C21.671 12.271 21.6968 12.1414 21.6968 12.0105C21.6968 11.8796 21.671 11.75 21.6209 11.6291C21.5707 11.5082 21.4972 11.3984 21.4046 11.3059L21.3226 11.2239C21.2301 11.1312 21.1203 11.0577 20.9994 11.0076C20.8785 10.9575 20.7489 10.9316 20.618 10.9316C20.4871 10.9316 20.3575 10.9575 20.2366 11.0076C20.1157 11.0577 20.0058 11.1312 19.9134 11.2239L14.1507 16.9836L11.0862 13.9192Z"
                fill="#4C989D"
              />
            </svg>
          </Box>
        )
      return (
        <Box direction="row" gap="xsmall">
          {event.type} VCheck
        </Box>
      )
    }

    if (event.resourcetype === 'BACCheckEvent') {
      if (
        event.status === 'on-time' ||
        event.status === 'late' ||
        event.status === 'grouped' ||
        event.status === 'completed'
      )
        return (
          <Box direction="row" gap="xsmall" justify="center" alignContent="center">
            <Box justify="center" alignContent="center">
              {event.type} BrAC Check
            </Box>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <g transform="translate(0, 30) scale(0.005859, -0.005859)">
                <path
                  d="M1085 5101 c-96 -43 -144 -125 -145 -243 l0 -78 -284 0 c-278 0 -284 0 -317 -23 -18 -12 -44 -38 -56 -56 -22 -33 -23 -41 -23 -266 0 -224 1 -233 23 -266 12 -19 42 -44 67 -56 43 -22 54 -23 317 -23 l273 0 0 -77 c0 -91 22 -145 78 -194 55 -49 108 -69 182 -69 78 0 131 22 183 74 46 45 63 78 71 139 l7 47 165 0 164 0 0 -752 c0 -1007 -4 -976 185 -1338 80 -151 75 -92 75 -957 0 -838 -2 -802 54 -876 14 -19 48 -46 75 -61 l49 -26 1109 2 1108 3 42 22 c22 12 55 39 72 60 62 75 61 61 61 898 0 468 4 783 10 815 7 35 27 80 60 130 88 138 135 269 166 467 16 99 17 212 15 1317 -2 992 -5 1216 -17 1248 -21 59 -61 104 -117 133 l-51 25 -1355 0 c-1534 0 -1395 7 -1478 -83 -33 -36 -44 -57 -54 -106 l-12 -61 -163 0 -164 0 0 30 c0 69 -71 168 -145 201 -55 25 -175 25 -230 0z m3547 -161 c21 -6 43 -21 50 -33 20 -34 12 -2355 -8 -2483 -56 -366 -320 -727 -662 -907 -223 -117 -402 -152 -744 -144 -235 5 -328 20 -478 77 -404 156 -693 505 -793 960 -20 93 -21 120 -24 1286 l-4 1191 21 26 21 27 567 3 c1611 7 2018 6 2054 -3z m-3373 -26 c21 -26 21 -37 21 -478 l0 -452 -29 -28 c-25 -24 -35 -27 -65 -22 -72 14 -71 7 -71 491 0 236 3 442 8 457 11 41 34 58 77 58 30 0 43 -6 59 -26z m531 -474 l0 -250 -165 0 -165 0 0 250 0 250 165 0 165 0 0 -250z m-850 -5 l0 -165 -250 0 -250 0 0 165 0 165 250 0 250 0 0 -165z m1358 -2886 c174 -156 420 -277 662 -327 127 -25 628 -25 750 1 246 53 464 160 665 328 l60 50 3 -676 c2 -740 3 -726 -56 -742 -15 -5 -496 -8 -1070 -8 l-1043 0 -19 25 c-19 24 -20 50 -20 713 0 378 3 687 6 687 3 0 31 -23 62 -51z"
                  fill="#4C989D"
                />
                <path
                  d="M3145 4685 c-488 -91 -830 -442 -910 -935 -22 -137 -22 -1097 0 -1222 40 -234 152 -438 331 -608 199 -189 428 -287 697 -298 618 -24 1086 366 1171 976 22 157 23 1010 1 1152 -34 226 -132 432 -281 590 -157 166 -331 273 -529 326 -127 33 -353 42 -480 19z m420 -194 c176 -47 382 -177 494 -313 47 -58 111 -170 126 -225 l6 -23 -861 0 -862 0 7 28 c40 167 277 393 516 494 169 71 397 86 574 39z m584 -730 c52 -6 96 -13 98 -15 2 -2 10 -75 18 -162 16 -169 19 -342 9 -443 l-7 -61 -932 0 -933 0 -7 216 c-7 199 -3 339 11 422 l6 32 89 0 89 0 -10 -26 c-15 -38 -12 -87 6 -112 12 -17 25 -22 62 -22 44 0 49 3 107 65 79 85 140 109 262 103 46 -2 86 -7 89 -11 4 -4 -77 -90 -179 -192 -185 -184 -187 -187 -187 -229 0 -59 23 -78 86 -74 l48 3 80 106 c163 213 257 302 382 361 112 52 161 58 454 54 146 -3 307 -10 359 -15z m118 -1023 c-6 -190 -25 -273 -91 -413 -68 -143 -147 -234 -311 -360 -72 -55 -217 -121 -321 -146 -77 -18 -109 -21 -229 -16 -209 8 -337 47 -500 152 -184 119 -321 310 -374 518 -23 88 -41 245 -41 353 l0 74 936 0 937 0 -6 -162z"
                  fill="#4C989D"
                />
              </g>
            </svg>
          </Box>
        )
      return (
        <Box direction="row" gap="xsmall">
          {event.type} BrAC Check
        </Box>
      )
    }

    return ''
  }

  const getLocation = event => {
    if (event && event.address) {
      return (
        <Box>
          {event.address && event.address
            ? `${
                typeof event.address === 'string'
                  ? event.address
                  : `${
                      event.address.formattedAddress
                        ? event.address.formattedAddress
                        : 'Street Address Not Available'
                    }`
              }`
            : 'Street Address Not Available'}
        </Box>
      )
    }

    return 'No Location'
  }

  const bodyData = []

  orderedEvents.forEach((event, index) => {
    let zoneName = 'None'
    let zoneType = 'None'
    if (event.data && event.data.geolocationMeta && event.data.geolocationMeta.geofence) {
      zoneName = event.data.geolocationMeta.geofence.identifier
      zoneType = event.data.geolocationMeta.geofence.extras.type
      if (zoneType === 'exclusion') zoneType = 'Zone Of Interest'
    }
    const eventRowObject = {
      id: index + 1,
      type: getEventType(event),
      time:
        event.resourcetype === 'Application Alert' || event.type === 'no_activity_alert'
          ? moment.utc(event.alert_date_time).toISOString()
          : event.created,
      bacResult: event.bac_result ? parseFloat(event.bac_result).toFixed(3) : 'N/A',
      location: getLocation(event),
      zoneName,
      zoneType,
      event,
    }
    bodyData.push(eventRowObject)
  })

  const fieldProps = {}

  if (!printable) {
    fieldProps.progressPending = loading
    fieldProps.progressComponent = <LoadingIndicator />
  }

  return (
    <>
      <StyledDataTable
        noHeader
        border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
        columns={dataTableHeaders}
        data={bodyData}
        sortable
        striped
        highlightOnHover
        pointerOnHover
        onRowClicked={e => {
          if (e.event && e.event.latitude && e.event.longitude) {
            setCustomCenter({
              lat: e.event.latitude,
              lng: e.event.longitude,
            })
            const element = document.getElementById('google-map-component')
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        }}
        pagination={!printable}
        paginationServer
        paginationTotalRows={rowCount}
        paginationPerPage={25}
        paginationRowsPerPageOptions={getTablePaginationOptions(rowCount)}
        onChangeRowsPerPage={e => handleRowsPerPageChange(e)}
        onChangePage={e => handlePageChange(e)}
        persistTableHead
        {...fieldProps}
      />
    </>
  )
}

const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  nav > div > select {
    padding-right: 24px;
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-transform: capitalize;
`

MonitoringTable.propTypes = {
  loading: PropTypes.bool,
  printable: PropTypes.bool,
  clientEvents: PropTypes.array,
  clientTimezone: PropTypes.string,
  setCustomCenter: PropTypes.func,
  rowCount: PropTypes.number,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
}

export default MonitoringTable
