import React from 'react'
import { FormattedMessage } from 'react-intl'
import { orderBy, startCase, toLower } from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'

// Components
import { Box } from '../Box'
import { TableHeader } from '../Table'

// Utils, Services & Messages
import messages from './ClientComplianceTable.messages'
import { LoadingIndicator } from '../LoadingIndicator'
import { getTablePaginationOptions } from '../../utils/helpers'

/**
 * ClientComplianceTable
 *
 * Timezone Notes:
 *  - created datetime is displayed in client timezone because it is client-created
 *
 */
const ClientComplianceTable = ({
  loading,
  printable,
  clientEvents,
  clientTimezone,
  rowCount,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const orderedEvents = orderBy(clientEvents, ['created'], ['desc'])

  const dataTableHeaders = [
    {
      selector: 'id',
      name: '',
      omit: true,
    },
    {
      selector: 'type',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.6 : 1,
      wrap: true,
    },
    {
      selector: 'checkin_type',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTwo} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      wrap: true,
    },
    {
      selector: 'time',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderThree} />
        </StyledTableHeader>
      ),
      format: row => {
        if (row.time) {
          return moment(row.time)
            .tz(clientTimezone)
            .format('MM/DD/YY hh:mm A z')
        }
        return 'Not Found'
      },
      sortable: false,
      style: { 'font-size': 14 },
      grow: printable ? 0.5 : 1,
      wrap: true,
    },
    {
      selector: 'location',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFour} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      wrap: true,
    },
    {
      selector: 'bac_result',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFive} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      wrap: true,
    },
    {
      selector: 'zoneName',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderSix} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'zoneType',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderSeven} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'event',
      omit: true,
    },
  ]

  const getEventType = event => {
    if (event.resourcetype === 'Event') {
      const type = startCase(toLower(event.type))
      const classifier = event.classifier ? `:${startCase(toLower(event.classifier))}` : ''
      const typeClassifier = `${type}${classifier}`
      return typeClassifier
    }

    if (event.resourcetype === 'VCheckEvent') {
      return `Missed ${event.event_type} VCheck`
    }

    if (event.resourcetype === 'BACCheckEvent') {
      return `${event.status} ${event.event_type} BrAC Check`
    }

    return ''
  }

  const getLocation = event => {
    try {
      if (event && event.address && event.address.formattedAddress) {
        return (
          <Box>
            {event && event.address && event.address.formattedAddress
              ? event.address.formattedAddress
              : 'Street Address Not Available'}
          </Box>
        )
      }
      return 'No Location'
    } catch (err) {
      return 'No Location'
    }
  }

  const bodyData = []

  orderedEvents.forEach((event, index) => {
    console.log('event', event)
    let zoneName = 'None'
    let zoneType = 'None'
    if (event && event.zone_type && event.zone_name) {
      zoneName = event.zone_name
      zoneType = event.zone_type
      if (zoneType === 'exclusion') zoneType = 'Zone Of Interest'
    }
    const eventRowObject = {
      id: index + 1,
      type: getEventType(event),
      time: event.created,
      location: getLocation(event),
      zoneName,
      zoneType,
      event,
      checkin_type: event.checkin_type === 'bac_checks' ? 'BrAC Check' : 'Vcheck',
      bac_result: event.checkin_type === 'bac_checks' && event.bac_result ? parseFloat(event.bac_result).toFixed(3) : '',
    }
    bodyData.push(eventRowObject)
  })

  const fieldProps = {}

  if (!printable) {
    fieldProps.progressPending = loading
    fieldProps.progressComponent = <LoadingIndicator />
  }

  return (
    <>
      <StyledDataTable
        noHeader
        border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
        columns={dataTableHeaders}
        data={bodyData}
        striped
        pagination={!printable}
        paginationServer
        paginationTotalRows={rowCount}
        paginationPerPage={25}
        paginationRowsPerPageOptions={getTablePaginationOptions(rowCount)}
        onChangeRowsPerPage={e => handleRowsPerPageChange(e)}
        onChangePage={e => handlePageChange(e)}
        persistTableHead
        {...fieldProps}
      />
    </>
  )
}

const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }

  nav > div > select {
    padding-right: 24px;
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-transform: capitalize;
`

ClientComplianceTable.propTypes = {
  loading: PropTypes.bool,
  printable: PropTypes.bool,
  clientEvents: PropTypes.array,
  clientTimezone: PropTypes.string,
  setCustomCenter: PropTypes.func,
  rowCount: PropTypes.number,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
}

export default ClientComplianceTable
