/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { Tab } from 'grommet'
import { doc } from './Tab.doc'
import { createWithDoc } from '../../utils/helpers'
import colors from '../../utils/colors'

/**
 *
 * Tabs
 *
 * Displays Material Design style Tabs
 *
 * - When clicked, it renders a different content panel and applies styles to the active tab
 *
 */

const StyledTab = styled(Tab).attrs(props => ({ ...props }))`
  max-width: ${({ custom }) => (custom ? '140px' : 'none')};

  /* Target the inner div of the Tab */
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px; /* Adjust padding as needed */
    margin: 0 12px 12px 0;
    min-height: 40px;
    line-height: 1.2;
    word-break: break-word;
    border-bottom: ${({ active }) =>
      active ? `3px solid ${colors.secondary80}` : `3px solid ${colors.primary}`};
    color: ${({ active }) => (active ? colors.secondary80 : colors.primary)};
    white-space: ${({ custom }) => (custom ? 'normal' : 'nowrap')};
    word-wrap: break-word;
    font-size: 14px;
    height: 2.4em; /* Ensures consistent height */
    text-align: center; /* Ensure text is centered horizontally */

    /* Hover styles */
    &:hover {
      color: ${({ disabled }) => !disabled && `${colors.secondary80}`};
      border-color: ${({ disabled }) => !disabled && `${colors.secondary80}`};
    }

    /* Ensure the text inside the div is centered */
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
`

const createTab = ({
  active,
  forwardRef,
  plain,
  title,
  onMouseOver,
  onMouseOut,
  onFocus,
  onBlur,
  theme,
  disabled,
  custom,
  ...rest
}) => (
  <StyledTab
    active={active}
    forwardRef={forwardRef}
    plain={plain}
    title={title}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onFocus={onFocus}
    onBlur={onBlur}
    theme={theme}
    disabled={disabled}
    custom={custom}
    {...rest}
  />
)

export default createWithDoc({
  docFunction: doc,
  component: createTab,
})
