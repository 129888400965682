/* eslint-disable react/prefer-stateless-function */
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import FileSaver from 'file-saver'
import { FormDown, FormUp, Search } from 'grommet-icons'
import XLSX from 'xlsx'
import { map as _map, throttle as _throttle, startCase, toLower } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import 'moment-timezone'
import html2canvas from 'html2canvas'

// Components
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { ClientAvatar } from 'components/ClientAvatar'
import { ClientLocationsMap } from 'components/ClientLocationsMap'
import { Collapsible } from 'components/Collapsible'
import { DateRangePicker } from 'components/DateRangePicker'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Link } from 'components/Link'
import { Message } from 'components/Message'
import { MonitoringTable } from 'components/MonitoringTable'
import { Text } from 'components/Text'
import { Checkbox } from 'components/Checkbox'

// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'

// Utils, Services & Messages
import messages from './ClientMonitoringPage.messages'
import useFlashMessage from '../../hooks/FlashMessage'
import { getAllClientLocationsById } from '../../services/agency.service'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

// Styles
import colors from '../../utils/colors'

const DOWNLOAD_STATES = {
  idle: 0,
  downloading: 1,
  downloaded: 2,
}

const initialDateRangeState = {
  startDate: moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD'),
  startTime: '00:00',
  endDate: moment().format('YYYY-MM-DD'),
  endTime: '23:59',
}

let perPageCount = 25
let currentPage = 1

/**
 *
 * ClientMonitoringPage
 *
 * This container holds all components needed for the Client Monitoring Page
 * The user navigates to this page when they click on the "Location Tracking" button
 * on the Client Summary Page.
 *
 * The map displayed at the top of the page defaults to the center and zoom
 * defined above in `defaultProps`. The user can click on the location value
 * in each row to update the map location using the `setSinglePin` function,
 * which is passed into the MonitoringTable component.
 *
 * Timezone Notes:
 *  - created datetime (passed to table) is received as utc and displayed
 *    to user in client's timezone
 *  - date filters are displayed to the user as timezone naive, but are
 *    converted to utc relative to clients timezone so that filtering
 *    behaves as expected
 *
 */
const ClientMonitoringPage = () => {
  const { getAgencyById } = useContext(AgencyStoreContext)
  const { getClientById, getAllClientEventsById, distributorClientSelectedAgencyId } = useContext(
    ClientWizardStoreContext,
  )
  const { user, isDistributor } = useContext(UserStoreContext)
  const ClientWizardAvatarURL = useContext(ClientWizardStoreContext)

  const [pageLoadError, showPageLoadError] = useState()
  const [pageLoading, setPageLoading] = useState()
  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [loading, setLoading] = useState(null)

  const [client, setClient] = useReducer(
    (state, updatedField) => ({ ...state, ...updatedField }),
    {},
  )
  const [clientEvents, setClientEvents] = useState([])
  const [filterdClientEvents, setFilteredClientEvents] = useState([])
  const [clientZones, setClientZones] = useState([])
  const [agencyName, setAgencyName] = useState('Agency')
  const [downloadState, setDownloadState] = useState(DOWNLOAD_STATES.idle)
  const [showVcheckStatus, setShowOnTimeStatus] = React.useState(true)
  const [showBacStatus, setShowLateStatus] = React.useState(true)
  const [showZonesStatus, setShowMissedStatus] = React.useState(true)
  const [showAppAlertsStatus, setShowGroupedStatus] = React.useState(true)
  const [showLocationTace, setShowLocationTrace] = React.useState(true)

  const [dateRange, setDateRange] = useReducer(dateRangeReducer, initialDateRangeState)
  const [filteredStartDate, setFilteredStartDate] = useState()
  const [filteredEndDate, setFilteredEndDate] = useState()

  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false)
  const [dateFilterApplied, setDateFilterApplied] = useState(false)
  const [agencyId, setAgencyId] = useState(
    isDistributor ? distributorClientSelectedAgencyId : user.agencyId,
  )

  function dateRangeReducer(state, action) {
    if (action.type !== 'reset') {
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }
    return initialDateRangeState
  }
  const removeDuplicateObjectsSetClientEvents = response => {
    const events = JSON.parse(JSON.stringify(response.results))
    /*
      Commented this removeDuplicateObjects code assuming the latest android apk solved the duplicate geo locations
      events = removeDuplicateObjects(events)
    */
    setClientEvents(events)
    const rowsCount = response.count - (response.results.length - events.length)
    setRowCount(rowsCount)
  }

  useEffect(() => {
    /**
     * @todo Refactor this:
     * - Having synchronous awaits and API calls that share loading/errors is an anti-pattern. They
     *   should each have individual loading/error states and should not wait on each other in a
     *   chain. This often results in long page load times, since each request waits for the
     *   previous response to return
     * - Also, we should never have an `await` outside of a try/catch
     */
    async function setDefaultData() {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('id')) {
        const agency = await getAgencyById(agencyId, showPageLoadError, setPageLoading)
        const currentClient = await getClientById(
          agencyId,
          urlParams.get('id'),
          showPageLoadError,
          setPageLoading,
        )

        const zones = await getAllClientLocationsById(
          agencyId,
          urlParams.get('id'),
          showPageLoadError,
          setPageLoading,
        )

        // set agency name
        setAgencyName(agency.name)
        setClient(currentClient)
        setClientZones(zones)

        setDateTimePickerOpen(true)
      }
    }
    setDefaultData()
  }, [])

  useEffect(() => {
    const filteredClientEvents = clientEvents.filter(vcheck => {
      if (vcheck.resourcetype === 'VCheckEvent') {
        return showVcheckStatus
      }
      if (vcheck.resourcetype === 'BACCheckEvent') {
        return showBacStatus
      }
      if (vcheck.type === 'zone') {
        return showZonesStatus
      }
      if (vcheck.resourcetype === 'Application Alert' || vcheck.type === 'no_activity_alert') {
        return showAppAlertsStatus
      }
      if (vcheck.type === 'geo') {
        return showLocationTace
      }
      return showLocationTace
    })
    setFilteredClientEvents(filteredClientEvents)
    if (
      !showVcheckStatus ||
      !showBacStatus ||
      !showZonesStatus ||
      !showAppAlertsStatus ||
      !showLocationTace
    ) {
      setRowCount(filterdClientEvents.length)
      console.log('filterdClientEvents.length', filterdClientEvents.length)
    } else {
      console.log('totalRowCount', totalRowCount)
      if (totalRowCount && totalRowCount > 0) {
        setRowCount(totalRowCount)
      }
    }
  }, [
    clientEvents,
    showVcheckStatus,
    showBacStatus,
    showZonesStatus,
    showAppAlertsStatus,
    showLocationTace,
  ])

  useEffect(() => {
    if (dateTimePickerOpen) {
      handleDateFilter()
    }
  }, [dateTimePickerOpen])

  // pagination
  const [totalRowCount, setTotalRowCount] = useState()
  const [rowCount, setRowCount] = useState()

  const handlePageOrCountChange = _throttle(async () => {
    const response = await getAllClientEventsById(
      agencyId,
      client.id,
      showError,
      setLoading,
      currentPage,
      perPageCount,
      true, // has_point
      filteredStartDate,
      filteredEndDate,
    )
    if (response) {
      removeDuplicateObjectsSetClientEvents(response)
      setTotalRowCount(response.count)
      setRowCount(response.count)
      return response.results
    }
    return null
  }, 500)

  const handleRowsPerPageChange = async perPage => {
    perPageCount = perPage

    handlePageOrCountChange()
  }

  const handlePageChange = async page => {
    currentPage = page

    handlePageOrCountChange()
  }

  const handleDateFilter = async () => {
    // convert to utc and format to send to API
    let startDateTime = dateRange.startTime
      ? moment.tz(dateRange.startDate.concat(' ', dateRange.startTime), client.timezone)
      : moment.tz(dateRange.startDate.concat(' ', '00:00'), client.timezone)

    startDateTime = startDateTime.utc().format('YYYY-MM-DD HH:mm')

    let endDateTime
    if (dateRange.endDate) {
      endDateTime = dateRange.endTime
        ? moment.tz(dateRange.endDate.concat(' ', dateRange.endTime), client.timezone)
        : moment.tz(dateRange.endDate.concat(' ', '23:59'), client.timezone)

      endDateTime = endDateTime.utc().format('YYYY-MM-DD HH:mm')
    }

    setFilteredStartDate(startDateTime)
    setFilteredEndDate(endDateTime)

    const response = await getAllClientEventsById(
      agencyId,
      client.id,
      showError,
      setLoading,
      1,
      perPageCount,
      true, // has_point
      startDateTime, // created_after
      endDateTime, // created_before
    )
    if (response) {
      removeDuplicateObjectsSetClientEvents(response)
      setDateFilterApplied(true)
      setTotalRowCount(response.count)
    }
  }

  const handleDateFilterClear = async () => {
    let startDateTime = moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
    startDateTime = moment.tz(startDateTime.concat(' ', '0:0'), client.timezone)
    startDateTime = startDateTime.utc().format('YYYY-MM-DD HH:mm')

    let endDateTime = moment.tz(dateRange.endDate.concat(' ', '23:59'), client.timezone)
    endDateTime = endDateTime.utc().format('YYYY-MM-DD HH:mm')

    setFilteredStartDate(startDateTime)
    setFilteredEndDate(endDateTime)

    const response = await getAllClientEventsById(
      agencyId,
      client.id,
      showError,
      setLoading,
      1,
      perPageCount,
      true, // has_point
      startDateTime, // created_after
      endDateTime, // created_before
    )
    if (response) {
      removeDuplicateObjectsSetClientEvents(response)
      setDateFilterApplied(false)
      setDateRange({ type: 'reset' })
      setFilteredStartDate(null)
      setFilteredEndDate(null)
      setTotalRowCount(response.count)
    }
  }

  // map data
  const [customCenter, setCustomCenter] = useState(null)

  // Download functions
  const getLocation = event => {
    if (event && event.address) {
      return event.address && event.address ? event.address : 'Street Address Not Available'
    }
    return 'No Location'
  }
  const getEventType = event => {
    if (event.resourcetype === 'Application Alert') {
      return event.status
    }
    if (event.type === 'no_activity_alert') {
      return 'No GPS Alert'
    }
    if (event.resourcetype === 'Event') {
      const type = startCase(toLower(event.type))
      const classifier = event.classifier ? `:${startCase(toLower(event.classifier))}` : ''
      const typeClassifier = `${type}${classifier}`
      if (type === 'Geo' || type === 'App') {
        return 'Location Trace'
      }
      return typeClassifier
    }
    if (event.resourcetype === 'VCheckEvent') {
      return 'Zone-Triggered VCheck'
    }
    return ''
  }
  function downloadPDF() {
    setDownloadState(DOWNLOAD_STATES.downloading)

    /* column headers*/
    const columnList = [
      { dataKey: 'type', title: 'EVENT TYPE' },
      { dataKey: 'bacResult', title: 'BrAC RESULT' },
      { dataKey: 'time', title: 'CREATED' },
      { dataKey: 'location', title: 'LOCATION' },
      { dataKey: 'zoneName', title: 'ZONE NAME' },
      { dataKey: 'zoneType', title: 'ZONE TYPE' },
    ]

    html2canvas(document.getElementById('google-map-component'), {
      allowTaint: false,
      useCORS: true,
      ignoreElements: function(node) {
        return node.nodeName === 'IFRAME'
      },
    })
      .then(function(canvas) {
        const geoEvents = []
        filterdClientEvents.forEach(event => {
          console.log('event', event)
          let zoneName = 'None'
          let zoneType = 'None'
          if (event.data && event.data.geolocationMeta && event.data.geolocationMeta.geofence) {
            zoneName = event.data.geolocationMeta.geofence.identifier
            zoneType = event.data.geolocationMeta.geofence.extras.type
            if (zoneType === 'exclusion') zoneType = 'Zone Of Interest'
          }
          const eventRowObject = {
            type: getEventType(event),
            bacResult: event.bac_result || 'N/A',
            time:
              event.resourcetype === 'Application Alert' || event.type === 'no_activity_alert'
                ? convertToClientTimezone(moment.utc(event.alert_date_time))
                : convertToClientTimezone(moment(event.created)),
            location: getLocation(event),
            zoneName,
            zoneType,
            event,
          }
          geoEvents.push(eventRowObject)
        })

        let pdf = new jsPDF('l', 'px', [1122, 100 * columnList.length])

        /* report summary details*/
        const activationDate = `${
          client.monitoring_start
            ? convertToClientTimezone(moment(client.monitoring_start))
            : 'Not Found'
        }`
        const deActivationDate = `${
          client.monitoring_end
            ? convertToClientTimezone(moment(client.monitoring_end))
            : 'Not Set'
        }`
        const name = `${agencyName} / ${client.first_name} ${client.last_name}`
        const clientId = `${client.id}`
        const phoneNo = `${client.phone}`
        const caseId = `${client.case_id}`

        pdf.setFontSize(20)
        pdf.setTextColor(1, 3, 2)
        pdf.text(name, 20, 20)

        /*report summary title styles*/
        pdf.setFontSize(14)
        pdf.text('Client Id', 20, 40)
        pdf.text('Case Id', 210, 40)
        pdf.text('Phones', 320, 40)
        pdf.text('Activation Date', 20, 80)
        pdf.text('DeActivation Date', 210, 80)
        pdf.text('From:', 20, 120)
        pdf.text('To:', 210, 120)

        /*report summary details styles*/
        pdf.setTextColor(100)
        pdf.text(clientId, 20, 60)
        pdf.text(caseId, 210, 60)
        pdf.text(phoneNo, 320, 60)
        pdf.text(activationDate, 20, 100)
        pdf.text(deActivationDate, 210, 100)
        pdf.text(convertToClientTimezone(moment(filteredStartDate + 'z')), 50, 120)
        pdf.text(convertToClientTimezone(moment(filteredEndDate + 'z')), 230, 120)

        /*binding map image to pdf*/
        const divImage = canvas.toDataURL('image/png')
        const width = pdf.internal.pageSize.getWidth()
        pdf.addImage(divImage, 'PNG', 10, 140, width - 20, 350)
        pdf.addPage()

        /* binding geoevents to pdf*/
        pdf.autoTable({
          columns: columnList,
          body: geoEvents,
          margin: { top: 10, right: 20, bottom: 20, left: 20 },
          headStyles: {
            fillColor: '#284b63',
            textColor: '#fff',
            lineWidth: 0.1,
          },
          bodyStyles: {
            lineColor: '#000000',
          },
          styles: { fontSize: 14 },
          theme: 'striped',
        })

        pdf.save(`VCheck24 Compliance Report - ${client.last_name}, ${client.first_name}`)
      })
      .catch(error => error)

    // Flash the download completed message
    setDownloadState(DOWNLOAD_STATES.downloaded)
    window.setTimeout(() => {
      setDownloadState(DOWNLOAD_STATES.idle)
    }, 3000)
  }

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const convertToClientTimezone = time => time.tz(client.timezone).format('MM/DD/YY hh:mm:ss A z')

  function downloadCSV() {
    setDownloadState(DOWNLOAD_STATES.downloading)
    const getZoneType = zoneType => (zoneType === 'exclusion' ? 'Zone Of Interest' : zoneType)
    const dataToExport = _map(filterdClientEvents, event => ({
      Category: event.resourcetype,
      Point:
        event.point && event.point.coordinates
          ? `${event.point.coordinates[1]}, ${event.point.coordinates[0]}`
          : null,
      Type: event.type && event.type === 'no_activity_alert' ? 'No GPS Alert' : event.type,
      'BrAC Result': event.bac_result || 'N/A',
      Status: event.status,
      Address: event.address ? event.address : null,
      Scheduled: event.scheduled ? convertToClientTimezone(moment(event.scheduled)) : null,
      Completed: event.completed ? convertToClientTimezone(moment(event.completed)) : null,
      Created:
        event.resourcetype === 'Application Alert' || event.type === 'no_activity_alert'
          ? convertToClientTimezone(moment.utc(event.alert_date_time))
          : convertToClientTimezone(moment(event.created)),
      'Zone Name':
        event.data && event.data.geolocationMeta && event.data.geolocationMeta.geofence
          ? event.data.geolocationMeta.geofence.identifier
          : 'None',
      'Zone Type':
        event.data && event.data.geolocationMeta && event.data.geolocationMeta.geofence
          ? getZoneType(event.data.geolocationMeta.geofence.extras.type)
          : 'None',
      latitude: event.latitude ? event.latitude : null,
      longitude: event.longitude ? event.longitude : null,
    }))
    const JSONToSheet = XLSX.utils.json_to_sheet(dataToExport)
    const worksheet = XLSX.utils.sheet_to_csv(JSONToSheet)
    const filename = `VCheck24 Locations - ${client.last_name}, ${client.first_name}.csv`

    FileSaver.saveAs(new Blob([s2ab(worksheet)], { type: 'application/octet-stream' }), filename)

    // Flash the download completed message
    setDownloadState(DOWNLOAD_STATES.downloaded)
    window.setTimeout(() => {
      setDownloadState(DOWNLOAD_STATES.idle)
    }, 3000)
  }

  // If we don't have a client id in our data object, display a loading message
  if (!client.id || pageLoading || pageLoadError) {
    if (pageLoadError) {
      return (
        <Box fill justify="center" align="center">
          <Message message={pageLoadError} isError />
        </Box>
      )
    }
    return (
      <Header mini level="5" margin="medium">
        <FormattedMessage {...messages.loading} />
      </Header>
    )
  }
  return (
    <>
      <Grid
        responsive
        rows={['auto', '40vh', 'auto']}
        columns={['auto']}
        gap="small"
        areas={[['header'], ['map'], ['table']]}
      >
        <Box gridArea="header" direction="column" justify="between" gap="small" pad="medium">
          <Box direction="row-responsive">
            <Box
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              direction="row"
              align="center"
              justify="center"
              round
              border={{ color: 'light-3', size: 'xsmall' }}
            >
              <Text size="small">
                <Link to="/clients">Client List</Link>

                {' / '}

                <Link to={`/clients/summary?id=${client.id}`}>
                  {`${client.first_name.concat(' ', client.last_name)}`}
                </Link>

                {' / Location Tracking'}
              </Text>
            </Box>
          </Box>

          <Box direction="row-responsive" justify="start" align="center" gap="small">
            <Box
              background="light-2"
              justify="center"
              align="center"
              pad="none"
              round="full"
              height="150px"
              width="150px"
            >
              <ClientAvatar
                file={ClientWizardAvatarURL.clientAvatarURLValue.avatar_url}
                size="large"
                type={ClientWizardAvatarURL.clientAvatarURLValue.type}
              />
            </Box>

            <Box align="end" gap="small">
              <Text size="medium">{`${agencyName} / ${client.first_name} ${client.last_name}`}</Text>
            </Box>
          </Box>
        </Box>

        <>
          <Box
            gridArea="map"
            border={{ color: 'light-3', size: 'xsmall' }}
            justify="center"
            align="center"
            id="google-map-component"
          >
            <ClientLocationsMap
              events={filterdClientEvents}
              zones={clientZones}
              customCenter={customCenter}
              clientTimezone={client.timezone}
            />
          </Box>

          <Box gridArea="table" gap="xsmall" pad="medium">
            {(!!rowCount || !!dateTimePickerOpen) && (
              <Box direction="row" justify="between" align="start">
                <Box gap="small">
                  <Box direction="row" align="center" gap="xsmall">
                    <Header mini level="5" margin="none">
                      <FormattedMessage {...messages.datePickerHeader} />
                    </Header>

                    {!dateTimePickerOpen ? (
                      <FormDown
                        size="medium"
                        color="placeholder"
                        onClick={() => setDateTimePickerOpen(true)}
                      />
                    ) : (
                      <FormUp size="medium" onClick={() => setDateTimePickerOpen(false)} />
                    )}
                  </Box>

                  <Collapsible open={dateTimePickerOpen}>
                    <Box align="start" gap="none" margin={{ bottom: 'xlarge' }}>
                      <Box direction="row" pad={{ bottom: 'small' }} gap="small" align="center">
                        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />

                        <StyledButton
                          hoverIndicator
                          primary={false}
                          icon={<Search color={colors.primary} />}
                          onClick={handleDateFilter}
                        />
                      </Box>
                      <Collapsible open={dateFilterApplied}>
                        <StyledButton
                          primary={false}
                          color={colors.primary}
                          label="Clear Filters"
                          onClick={handleDateFilterClear}
                        />
                      </Collapsible>
                    </Box>
                  </Collapsible>
                </Box>

                {filterdClientEvents && filterdClientEvents.length > 0 && (
                  <Box direction="row" justify="end" align="center" gap="small">
                    {downloadState === DOWNLOAD_STATES.downloaded && (
                      <Text
                        color="focus"
                        weight="bold"
                        size="small"
                        margin={{ horizontal: 'small' }}
                      >
                        File has been downloaded.
                      </Text>
                    )}

                    <StyledButton
                      color={colors.primary}
                      disabled={downloadState === DOWNLOAD_STATES.downloading}
                      label="Download PDF"
                      onClick={downloadPDF}
                      reverse
                    ></StyledButton>

                    <StyledButton
                      color={colors.primary}
                      disabled={downloadState === DOWNLOAD_STATES.downloading}
                      label="Download CSV"
                      onClick={downloadCSV}
                      reverse
                    ></StyledButton>
                  </Box>
                )}
              </Box>
            )}

            <Box gridArea="errorMessage">{error && <Message message={error} isError />}</Box>

            <Box direction="column">
              <Box direction="row" justify="between" align="center" gap="small">
                <Box direction="column" gap="small">
                  <Text size="small">Event Type Filter: </Text>
                  <Box direction="row" gap="medium" pad="small">
                    <Checkbox
                      label="VChecks"
                      name="vchecks"
                      checked={showVcheckStatus}
                      onChange={event => setShowOnTimeStatus(event.target.checked)}
                    ></Checkbox>
                    <Checkbox
                      label="BrAC Checks"
                      name="brac-checks"
                      checked={showBacStatus}
                      onChange={event => setShowLateStatus(event.target.checked)}
                    ></Checkbox>
                    <Checkbox
                      label="Zones"
                      name="zones"
                      checked={showZonesStatus}
                      onChange={event => setShowMissedStatus(event.target.checked)}
                    ></Checkbox>
                    <Checkbox
                      label="GPS and App Alerts"
                      name="gps-alerts"
                      checked={showAppAlertsStatus}
                      onChange={event => setShowGroupedStatus(event.target.checked)}
                    ></Checkbox>
                    <Checkbox
                      label="Location Traces"
                      name="location-traces"
                      checked={showLocationTace}
                      onChange={event => setShowLocationTrace(event.target.checked)}
                    ></Checkbox>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box fill pad={{ bottom: 'medium' }}>
              <MonitoringTable
                loading={loading}
                clientEvents={filterdClientEvents}
                clientTimezone={client.timezone}
                setCustomCenter={setCustomCenter}
                rowCount={rowCount}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          </Box>
        </>
      </Grid>
    </>
  )
}

const StyledButton = styled(Button)`
  margin: 0 !important;
`

export default ClientMonitoringPage
