// eslint-disable-next-line import/no-cycle
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'
/**
 * Agency Service
 */

// AGENCY

export async function getAgencyById(agencyId, setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientStatasticDetailByAgencyId(
  agencyId,
  setError,
  setLoading,
  limit,
  currentPage,
  searchText,
) {
  setLoading(true)
  try {
    const data = await axios.get(`/agencies/${agencyId}/agency-dashboard-stats-data/`, {
      params: {
        limit,
        page: currentPage,
        agency_id: agencyId,
      },
    })

    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// AGENTS

export async function getAgentsByAgency(
  agencyId,
  setError,
  setLoading,
  currentPage,
  rowsPerPage,
  status,
  queryFilter,
  queryparameter,
) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/agents/`, {
      params: {
        page: currentPage,
        limit: rowsPerPage,
        status,
        name: queryFilter,
        user__is_active: queryparameter,
      },
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getAgentHomeEvents(agencyId, agentId, setError, setLoading, page, limit) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/agents/${agentId}/home/?expand=client`, {
      params: { page, limit },
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postAgentToAgency(agencyId, agent, setLoading, setError) {
  setLoading(true)
  try {
    const data = await axios.post(`/agencies/${agencyId}/agents/`, agent)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateAgentInAgency(agencyId, agent, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.patch(`/agencies/${agencyId}/agents/${agent.id}/`, agent)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    // API error message seems to be responding in the fllowing manner
    if (err.response.data.agency_id) {
      setError(err.response.data.agency_id.message)
      return null
    }
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// CLIENT

export async function getClientsByAgency(
  agencyId,
  setError,
  setLoading,
  page,
  rowsPerPage,
  searchText,
  status,
  agentId,
  customizeLoading,
  checkin_type
) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/`, {
      params: { limit: rowsPerPage, page, name: searchText, status, agentid: agentId,client_type:checkin_type },
    })
    setError(false)
    if (!customizeLoading) {
      setLoading(false)
    }
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientById(agencyId, clientId, setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientVCheckCount(agencyId, clientId, setError, setLoading) {
  setLoading(true)
  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/getvchecks_count/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientUnreadMessageCount(agencyId, clientId, setError, setLoading) {
  setLoading(true)
  try {
    const data = await axios.get(
      `/agencies/${agencyId}/clients/${clientId}/getunreadmessages_count/`,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
export async function getClientAvatarlUrl(
  agencyId,
  clientId,
  isVcheck = '',
  setError,
  setLoading,
) {
  setLoading(true)
  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/avatar-details/`, {
      params: {
        user_profile_image: isVcheck,
      },
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
export async function postClientToAgency(agencyId, client, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.post(`/agencies/${agencyId}/clients/`, client)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateClientInAgency(agencyId, client, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.patch(`/agencies/${agencyId}/clients/${client.id}/`, client)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// CLIENT LOCATIONS

export async function getAllClientLocationsById(agencyId, clientId, setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/zones/all/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postClientLocationsById(agencyId, clientId, location, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.post(`/agencies/${agencyId}/clients/${clientId}/zones/`, location)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateClientLocationById(
  agencyId,
  clientId,
  locationId,
  location,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/agencies/${agencyId}/clients/${clientId}/zones/${locationId}/`,
      location,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function deleteLocationById(agencyId, clientId, locationId, setLoading, setError) {
  setLoading(true)

  try {
    await axios.delete(`/agencies/${agencyId}/clients/${clientId}/zones/${locationId}/`)
    setError(false)
    setLoading(false)
    return true
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postGlobalZonesToClient(agencyId, clientId, zones, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.post(`/agencies/${agencyId}/clients/${clientId}/global-zones/`, {
      zones,
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// CLIENT SCHEDULES

export async function getClientSchedulesById(agencyId, clientId, setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/schedule/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientBacRandomSchedules(agencyId, clientId, setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/random-baccheck/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientBacSchedulesById(agencyId, clientId, setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/scheduled-bacchecks/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postOnDemandChatMessageToClient(
  agencyId,
  clientId,
  chatMessage,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.post(
      `/agencies/${agencyId}/clients/${clientId}/on-demand-chat-messages/`,
      chatMessage,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postScheduledChatMessageToClient(
  agencyId,
  clientId,
  chatMessage,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.post(
      `/agencies/${agencyId}/clients/${clientId}/scheduled-chat-messages/`,
      chatMessage,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateScheduledChatMessageById(
  agencyId,
  clientId,
  chatMessage,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/agencies/${agencyId}/clients/${clientId}/scheduled-chat-messages/${chatMessage.id}/`,
      chatMessage,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// export async function getScheduledChatMessages(
export async function getAllClientScheduledChatMessagesById(
  agencyId,
  clientId,
  setLoading,
  setError,
  page,
  limit,
) {
  setLoading(true)
  try {
    const data = await axios.get(
      `/agencies/${agencyId}/clients/${clientId}/scheduled-chat-messages/`,
      {
        params: { page, limit },
      },
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function deleteScheduledChatMessageById(
  agencyId,
  clientId,
  chatMessageId,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    await axios.delete(
      `/agencies/${agencyId}/clients/${clientId}/scheduled-chat-messages/${chatMessageId}/`,
    )
    setError(false)
    setLoading(false)
    return true
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postScheduledVCheckToClient(
  agencyId,
  clientId,
  vcheck,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.post(
      `/agencies/${agencyId}/clients/${clientId}/scheduled-vchecks/`,
      vcheck,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postScheduledBacCheckToClient(
  agencyId,
  clientId,
  baccheck,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.post(
      `/agencies/${agencyId}/clients/${clientId}/scheduled-bacchecks/`,
      baccheck,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateScheduledVCheckById(agencyId, clientId, vcheck, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/agencies/${agencyId}/clients/${clientId}/scheduled-vchecks/${vcheck.id}/`,
      vcheck,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateScheduledBacCheckById(
  agencyId,
  clientId,
  baccheck,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/agencies/${agencyId}/clients/${clientId}/scheduled-bacchecks/${baccheck.id}/`,
      baccheck,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function deleteScheduledVCheckById(
  agencyId,
  clientId,
  vcheckId,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    await axios.delete(`/agencies/${agencyId}/clients/${clientId}/scheduled-vchecks/${vcheckId}/`)
    setError(false)
    setLoading(false)
    return true
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function deleteScheduledBacCheckById(
  agencyId,
  clientId,
  bacCheckId,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    await axios.delete(
      `/agencies/${agencyId}/clients/${clientId}/scheduled-bacchecks/${bacCheckId}/`,
    )
    setError(false)
    setLoading(false)
    return true
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postRandomVCheckToClient(agencyId, clientId, vcheck, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.post(
      `/agencies/${agencyId}/clients/${clientId}/random-vcheck/`,
      vcheck,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postRandomBacCheckToClient(
  agencyId,
  clientId,
  vcheck,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.post(
      `/agencies/${agencyId}/clients/${clientId}/random-baccheck/`,
      vcheck,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateRandomVCheckById(
  agencyId,
  clientId,
  vcheckId,
  vcheck,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/agencies/${agencyId}/clients/${clientId}/random-vcheck/${vcheckId}/`,
      vcheck,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateRandomBacCheckById(
  agencyId,
  clientId,
  vcheckId,
  vcheck,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/agencies/${agencyId}/clients/${clientId}/random-baccheck/${vcheckId}/`,
      vcheck,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function deleteRandomVCheckById(agencyId, clientId, vcheckId, setLoading, setError) {
  setLoading(true)

  try {
    await axios.delete(`/agencies/${agencyId}/clients/${clientId}/random-vcheck/${vcheckId}/`)
    setError(false)
    setLoading(false)
    return true
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function deleteRandomBacCheckById(
  agencyId,
  clientId,
  vcheckId,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    await axios.delete(`/agencies/${agencyId}/clients/${clientId}/random-baccheck/${vcheckId}/`)
    setError(false)
    setLoading(false)
    return true
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// CLIENT EVENTS

export async function getAllClientEventsById(
  agencyId,
  clientId,
  setError,
  setLoading,
  page,
  limit,
  hasPoint,
  createdAfter,
  createdBefore,
) {
  setLoading(true)
  let data

  try {
    data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/events/all/`, {
      params: {
        page,
        limit,
        created_after: createdAfter,
        created_before: createdBefore,
        has_point: hasPoint,
      },
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// UNREAD CHAT MESSAGE COUNT
export async function getAgentUnreadChatMessageCount(agencyId) {
  let data

  try {
    data = await axios.get(
      `/agencies/${agencyId}/clients/null/chat-messages/unread-message-count/`,
    )
    return data
  } catch (err) {
    return null
  }
}

// CLIENT CHAT MESSAGES
export async function getAllClientChatMessagesById(
  agencyId,
  clientId,
  agentId,
  setError,
  setLoading,
  page,
  limit,
  createdAfter,
  createdBefore,
) {
  setLoading(true)
  let data

  try {
    data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/chat-messages/all/`, {
      params: {
        page,
        limit,
        agent_id: agentId,
        created_after: createdAfter,
        created_before: createdBefore,
      },
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientVCheckEventsById(
  agencyId,
  clientId,
  setError,
  setLoading,
  page,
  limit,
  pending,
  client_type = 'All',
) {
  setLoading(true)
  let data

  try {
    data = await axios.get(`/agencies/${agencyId}/checkin-review/`, {
      params: {
        page,
        limit,
        client_id: clientId,
        pending,
        client_type,
      },
    })
    console.log('data', data)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientVCheckVideoById(
  agencyId,
  clientId,
  documentPath,
  setError,
  setLoading,
) {
  setLoading(true)
  let data

  try {
    data = await axios.get(
      `/agencies/${agencyId}/clients/${clientId}/generate-video-signed-url/`,
      {
        params: {
          document_path: documentPath,
        },
      },
    )

    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateClientVCheckEventById(
  agencyId,
  clientId,
  vcheckId,
  vcheckReview,
  setError,
  setLoading,
) {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/agencies/${agencyId}/clients/${clientId}/checkin-review-status-update/`,
      vcheckReview,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// CLIENT REPORTS

export async function getClientComplianceReportsById(
  agencyId,
  clientId,
  setError,
  setLoading,
  page,
  limit,
  createdAfter,
  createdBefore,
  checkin_type
) {
  setLoading(true)
  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/reports/compliance/`, {
      params: { page, limit, created_after: createdAfter, created_before: createdBefore, checkin_type },
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// EXTRAS

export async function getClientCaseNotesById(agencyId, clientId, setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(`/agencies/${agencyId}/clients/${clientId}/case-notes/?limit=-1`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postCaseNoteToClient(
  agencyId,
  clientId,
  agentId,
  noteObject,
  setError,
  setLoading,
) {
  setLoading(true)

  try {
    const data = await axios.post(`/agencies/${agencyId}/clients/${clientId}/case-notes/`, {
      notes: noteObject.notes,
      created_by: agentId,
      client: clientId,
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getSupervisionTypes(setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(`/constants/supervision-types/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getTimezones(setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(`/constants/timezones/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateClientAvatarlUrl(agencyId, clientId, payLoad, setError, setLoading) {
  setLoading(true)
  try {
    const data = await axios.patch(
      `/agencies/${agencyId}/clients/${clientId}/update_avatar/`,
      payLoad,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientActiveDaysById(agencyId, clientId, setError, setLoading) {
  setLoading(true)
  try {
    const data = await axios.get(
      `/agencies/${agencyId}/clients/${clientId}/client-dashboard-active-days-count/`,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postClientOverrideFunction(agencyId, clientId, setError, setLoading) {
  setLoading(true)
  try {
    const data = await axios.post(
      `/agencies/${agencyId}/clients/${clientId}/update-override-status/`,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
