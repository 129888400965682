import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'moment-timezone'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import moment from 'moment'
// Components
import { TableHeader } from '../Table'
import { Text } from '../Text'
// Utils, Services & Messages
import messages from './VCheckReviewTable.messages'

import { LoadingIndicator } from '../LoadingIndicator'
import { getTablePaginationOptions } from '../../utils/helpers'
/**
 * VCheckReviewTable
 */
const VCheckReviewTable = ({
  clientVChecks,
  agencyName,
  rowCount,
  handleRowsPerPageChange,
  handlePageChange,
  editInSidebar,
  loading,
  isDistributorUser,
}) => {
  const dataTableHeaders = [
    {
      selector: 'clientName',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      sortable: true,
      style: {
        'font-size': 14,
      },
      grow: 1,
    },
    {
      selector: 'agencyName',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTwo} />
        </StyledTableHeader>
      ),
      sortable: true,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      omit: !isDistributorUser,
    },
    {
      selector: 'eventType',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderThree} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'bacresult',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFour} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'created',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFive} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: 1.3,
    },
    {
      selector: 'location',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderSix} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: 1.5,
    },
    {
      selector: 'facialRegStatus',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderEight} />
        </StyledTableHeader>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: 1.5,
    },
    {
      selector: 'status',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderSeven} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
  ]

  /**
   * @param acceptedState
   * Refactored the Old UI to show the status of the screen and the active vCheck
   * Show only Videos that have the status other than Missed
   * Need Clarification if these can be discarded from the dashboard on need to be kept on the Dashboard
   */

  const fieldProps = {}
  fieldProps.progressPending = loading
  fieldProps.progressComponent = <LoadingIndicator />

  const convertToClientTimezone = (time, zone) => time.tz(zone).format('MM/DD/YY hh:mm A z')

  const getVcheckLocation = vcheck => {
    try {
      if (vcheck && vcheck.address && vcheck.address.formattedAddress) {
        return vcheck.address.formattedAddress
      }
      return 'Unknown'
    } catch (err) {
      return 'Unknown'
    }
  }

  const getReviewStatus = status => {
    let recognitionStatus = ''

    switch (status) {
      case 'pending':
        recognitionStatus = 'Not Initiated'
        break
      case 'inprogress':
        recognitionStatus = 'In Progress'
        break
      case 'inititated':
        recognitionStatus = 'Not Initiated'
        break
      default:
        recognitionStatus = status
        break
    }

    return (
      <Text
        size="small"
        color={
          status === 'pending' || status === 'inititated' || status === 'inprogress'
            ? 'status-warning'
            : null
        }
      >
        {recognitionStatus}
      </Text>
    )
  }

  const getReview = acceptedState => {
    if (acceptedState.status === 'missed' || acceptedState.status === 'grouped') {
      return null
    }
    if (acceptedState.accepted === null || acceptedState.accepted === undefined) {
      return (
        <Text size="small" color="status-warning">
          Not Reviewed
        </Text>
      )
    }
    return <Text size="small">{acceptedState.accepted === true ? 'Approved' : 'Rejected'}</Text>
  }

  const bodyData = []
  clientVChecks.forEach(vcheck => {
    const eventRowObject = {
      clientName: vcheck.client_name || '',
      agencyName,
      eventType: (vcheck.type==='bac_checks') ? `BrAC -${vcheck.event_type}` : vcheck.event_type || '',
      bacresult: (vcheck.type === 'bac_checks' && vcheck.bac_result ? parseFloat(vcheck.bac_result).toFixed(3) : ''),
      created:
        vcheck.scheduled && vcheck.clientTimezone
          ? convertToClientTimezone(moment(vcheck.scheduled), vcheck.clientTimezone)
          : vcheck.scheduled || '',
      location: getVcheckLocation(vcheck),
      status: getReview(vcheck) || '',
      vcheckStatus: vcheck.status || null,
      scheduled: vcheck.scheduled || '',
      completed: vcheck.completed || '',
      facialRegStatus: getReviewStatus(vcheck.facial_recognition_status || ''),
      vcheck,
    }
    if (vcheck.status !== 'grouped') bodyData.push(eventRowObject)
  })

  return (
    <>
      <StyledDataTable
        noHeader
        border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
        columns={dataTableHeaders}
        data={bodyData}
        striped
        highlightOnHover
        pointerOnHover
        onRowClicked={e => {
          if (
            e.vcheckStatus === 'on-time' ||
            (e.vcheckStatus === 'late' &&
              (e.vcheck.facial_recognition_status !== 'inprogress' ||
                !e.vcheck.facial_recognition_status))
          ) {
            editInSidebar(e.vcheck)
          }
        }}
        pagination
        paginationServer
        paginationTotalRows={rowCount}
        paginationPerPage={25}
        paginationRowsPerPageOptions={getTablePaginationOptions(rowCount)}
        onChangeRowsPerPage={e => handleRowsPerPageChange(e)}
        onChangePage={e => handlePageChange(e)}
        persistTableHead
        {...fieldProps}
      />
    </>
  )
}

VCheckReviewTable.propTypes = {
  clientVChecks: PropTypes.array,
  agencyName: PropTypes.string,
  rowCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  editInSidebar: PropTypes.func,
  loading: PropTypes.bool,
  printable: PropTypes.bool,
  isDistributorUser: PropTypes.bool,
}
const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-align: center;
  text-transform: capitalize;
`
export default VCheckReviewTable
