import { defineMessages } from 'react-intl'

export const scope = 'app.containers.BacRandomScheduler'

/*
 * AddClientBacSchedule Messages
 *
 * This contains all the text for the AddClientBacSchedule container.
 */
export default defineMessages({
  // main page
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Client Data...',
  },
  bacRandomCheckHeader: {
    id: `${scope}.bacRandomCheckHeader`,
    defaultMessage: 'BRAC Random Checks',
  },
  bacRandomCheckDescription: {
    id: `${scope}.bacRandomCheckDescription`,
    defaultMessage:
      'Set one or more multiple random BrAC Check window per day and even choose which days of the week a random BrAC Check window should be apply.',
  },
  rFreqLabel: {
    id: `${scope}.rFreqLabel`,
    defaultMessage: 'Frequency',
  },
  rStartLabel: {
    id: `${scope}.rStartLabel`,
    defaultMessage: 'Start Time',
  },
  rEndLabel: {
    id: `${scope}.rEndLabel`,
    defaultMessage: 'End Time',
  },
})
