import { defineMessages } from 'react-intl'

export const scope = 'app.components.LocationsTable'

/*
 * LocationsTable Messages
 *
 * This contains all the text for the LocationsTable component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the LocationsTable component!',
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Name',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Address',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Type',
  },
  tableTypeExclustion: {
    id: `${scope}.tableTypeExclustion`,
    defaultMessage: 'Zone of Interest',
  },
  tableTypeSobriety: {
    id: `${scope}.tableTypeSobriety`,
    defaultMessage: 'Sobriety Check Zone ',
  },
  tableTypeInclustion: {
    id: `${scope}.tableTypeInclustion`,
    defaultMessage: 'Inclusion Zone',
  },
  tableTypeNone: {
    id: `${scope}.tableTypeNone`,
    defaultMessage: 'NONE',
  },
})
