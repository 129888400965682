import { defineMessages } from 'react-intl'

export const scope = 'app.components.ScheduledRandomBacTable'

/*
 * ScheduledVCheckTable Messages
 *
 * This contains all the text for the ScheduledVCheckTable component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the ScheduledRandomBacTable component!',
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'id',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Start',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Stop',
  },
  tableHeaderFour: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Frequency',
  },
  tableHeaderFive: {
    id: `${scope}.tableHeaderFive`,
    defaultMessage: 'Schedule',
  },
})
