import React, { useEffect, useContext, useReducer, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useReactToPrint } from 'react-to-print'
import FileSaver from 'file-saver'
import { FormDown, FormUp, Search } from 'grommet-icons'
import XLSX from 'xlsx'
import { map as _map, throttle as _throttle } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import 'moment-timezone'

// Components
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { ClientAvatar } from 'components/ClientAvatar'
import { ClientComplianceTable } from 'components/ClientComplianceTable'
import { Collapsible } from 'components/Collapsible'
import { DateRangePicker } from 'components/DateRangePicker'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Link } from 'components/Link'
import { Message } from 'components/Message'
import { PrintableComplianceReportPage } from 'components/PrintableComplianceReportPage'
import { Text } from 'components/Text'
import { Select } from '../../components/Select'


// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'
import { initialPaginationConstants as initialPagination } from '../../utils/helpers'

// Utils, Services & Messages
import messages from './ClientReportsPage.messages'
import useFlashMessage from '../../hooks/FlashMessage'

// Styles
import colors from '../../utils/colors'

const DOWNLOAD_STATES = {
  idle: 0,
  downloading: 1,
  downloaded: 2,
}

const initialDateRangeState = {
  startDate: moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD'),
  startTime: '00:00',
  endDate: moment().format('YYYY-MM-DD'),
  endTime: '23:59',
}

const options = [
  { value: 'All', label: 'All' },
  { value: 'vchecks', label: 'VChecks' },
  { value: 'bac_checks', label: 'BrAC Checks' }
];

let perPageCount = initialPagination.rows
let currentPage = initialPagination.pageNumber
/**
 *
 * ClientReportsPage
 *
 * This container holds all components needed for the Client Reports Page
 *
 * Timezone Notes:
 *  - created datetime (passed to table) is received as utc and displayed
 *    to user in client's timezone
 *  - date filters are displayed to the user as timezone naive, but are
 *    converted to utc relative to clients timezone so that filtering
 *    behaves as expected
 *
 */
const ClientReportsPage = () => {
  const { getAgencyById } = useContext(AgencyStoreContext)
  const {
    getClientById,
    getClientComplianceReportsById,
    distributorClientSelectedAgencyId,
  } = useContext(ClientWizardStoreContext)
  const { user, isDistributor } = useContext(UserStoreContext)
  const ClientWizardAvatarURL = useContext(ClientWizardStoreContext)

  const [pageLoadError, showPageLoadError] = useState()
  const [pageLoading, setPageLoading] = useState()
  const [selectedClientTypes, setselectedClientTypes] = useState('All');
  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [loading, setLoading] = useState(null)

  const [client, setClient] = useReducer(
    (state, updatedField) => ({ ...state, ...updatedField }),
    {},
  )
  const [clientEvents, setClientEvents] = useState([])
  const [agencyName, setAgencyName] = useState('Agency')
  const [downloadState, setDownloadState] = useState(DOWNLOAD_STATES.idle)

  const [dateRange, setDateRange] = useReducer(dateRangeReducer, initialDateRangeState)
  const [filteredStartDate, setFilteredStartDate] = useState()
  const [filteredEndDate, setFilteredEndDate] = useState()

  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false)
  const [dateFilterApplied, setDateFilterApplied] = useState(false)
  const agencyId = isDistributor ? distributorClientSelectedAgencyId : user.agencyId

  function dateRangeReducer(state, action) {
    if (action.type !== 'reset') {
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }
    return initialDateRangeState
  }

  useEffect(() => {
    async function setDefaultData() {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('id')) {
        const agency = await getAgencyById(agencyId, showPageLoadError, setPageLoading)
        const currentClient = await getClientById(
          agencyId,
          urlParams.get('id'),
          showPageLoadError,
          setPageLoading,
        )
        setAgencyName(agency.name)
        setClient(currentClient)
        setDateTimePickerOpen(true)
      }
    }
    setDefaultData()
  }, [])

  useEffect(() => {
    console.log('selectedClientTypes', selectedClientTypes);
    if (dateTimePickerOpen) {
      handleDateFilter(selectedClientTypes)
    }
  }, [dateTimePickerOpen,selectedClientTypes])

  // pagination
  const [totalRowCount, setTotalRowCount] = useState()
  const [rowCount, setRowCount] = useState()

  const handlePageOrCountChange = _throttle(async () => {
    const response = await getClientComplianceReportsById(
      agencyId,
      client.id,
      showError,
      setLoading,
      currentPage,
      perPageCount,
      filteredStartDate,
      filteredEndDate,
      selectedClientTypes
    )

    if (response) {
      setClientEvents(response.results)
      setTotalRowCount(response.count)
      setRowCount(response.count)
    }
  }, 500)

  const handleRowsPerPageChange = async perPage => {
    perPageCount = perPage

    handlePageOrCountChange()
  }

  const handlePageChange = async page => {
    currentPage = page

    handlePageOrCountChange()
  }

  const handleDateFilter = async (checkin_type) => {
    // convert to utc and format to send to API
    let startDateTime = dateRange.startTime
      ? moment.tz(dateRange.startDate.concat(' ', dateRange.startTime), client.timezone)
      : moment.tz(dateRange.startDate.concat(' ', '00:00'), client.timezone)

    startDateTime = startDateTime.utc().format('YYYY-MM-DD HH:mm')

    let endDateTime
    if (dateRange.endDate) {
      endDateTime = dateRange.endTime
        ? moment.tz(dateRange.endDate.concat(' ', dateRange.endTime), client.timezone)
        : moment.tz(dateRange.endDate.concat(' ', '23:59'), client.timezone)

      endDateTime = endDateTime.utc().format('YYYY-MM-DD HH:mm')
    }

    setFilteredStartDate(startDateTime)
    setFilteredEndDate(endDateTime)

    const response = await getClientComplianceReportsById(
      agencyId,
      client.id,
      showError,
      setLoading,
      1,
      perPageCount,
      startDateTime, // created_after
      endDateTime, // created_before
      selectedClientTypes
    )
    // let totalRows = 0
    if (response) {
      // totalRows = response.results.length
      setClientEvents(response.results)
      // if (dateFilterApplied) {
      //   setRowCount(response.results.length)
      // } else if (totalRows && totalRows > 0) {
      //   setRowCount(totalRows)
      // }
      setRowCount(response.count)
      setDateFilterApplied(true)
    }
  }

  const handleDateFilterClear = async () => {
    let startDateTime = moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
    startDateTime = moment.tz(startDateTime.concat(' ', '0:0'), client.timezone)
    startDateTime = startDateTime.utc().format('YYYY-MM-DD HH:mm')

    let endDateTime = moment.tz(dateRange.endDate.concat(' ', '23:59'), client.timezone)
    endDateTime = endDateTime.utc().format('YYYY-MM-DD HH:mm')

    setFilteredStartDate(startDateTime)
    setFilteredEndDate(endDateTime)

    const response = await getClientComplianceReportsById(
      agencyId,
      client.id,
      showError,
      setLoading,
      1,
      perPageCount,
      startDateTime, // created_after
      endDateTime, // created_before
      selectedClientTypes
    )
    if (response) {
      setClientEvents(response.results)
      setRowCount(response.count)
      setDateFilterApplied(false)
      setDateRange({ type: 'reset' })
    }
  }

  // Download functions
  const printComplianceReportRef = useRef()
  function downloadPDF() {
    handlePrintComplianceReport()
  }
  const handlePrintComplianceReport = useReactToPrint({
    content: () => printComplianceReportRef.current,
    documentTitle: `VCheck24 Compliance Report - ${client.last_name}, ${client.first_name}`,
  })

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const convertToClientTimezone = time => time.tz(client.timezone).format('MM/DD/YY hh:mm A z')

  function downloadCSV() {
    setDownloadState(DOWNLOAD_STATES.downloading)
    const getZoneType = zoneType => (zoneType === 'exclusion' ? 'Zone Of Interest' : zoneType)
    const dataToExport = _map(clientEvents, event => ({
      Category: event.resourcetype,
      'Check In Type': event.checkin_type === 'bac_checks' ? 'BrAC Check' : 'Vcheck',
      'BrAC Result': event.checkin_type === 'bac_checks' && event.bac_result ? parseFloat(event.bac_result).toFixed(3) : '',
      Point:
        event && event.address && event.address.latitude
          ? `${event.address.latitude}, ${event.address.longitude}`
          : null,
      Type: event.event_type,
      Status: event.status || '',
      Address: event.address ? event.address.formattedAddress : null,
      Scheduled: event.scheduled ? convertToClientTimezone(moment(event.scheduled)) : null,
      Created: event.created ? convertToClientTimezone(moment(event.created)) : null,
      'Zone Name': event && event.zone_name ? event.zone_name : 'None',
      'Zone Type': event && event.zone_type ? getZoneType(event.zone_type) : 'None',
    }))
    const JSONToSheet = XLSX.utils.json_to_sheet(dataToExport)
    const worksheet = XLSX.utils.sheet_to_csv(JSONToSheet)
    const filename = `VCheck24 Compliance Report - ${client.last_name}, ${client.first_name}.csv`

    FileSaver.saveAs(new Blob([s2ab(worksheet)], { type: 'application/octet-stream' }), filename)

    // Flash the download completed message
    setDownloadState(DOWNLOAD_STATES.downloaded)
    window.setTimeout(() => {
      setDownloadState(DOWNLOAD_STATES.idle)
    }, 3000)
  }

  // If we don't have a client id in our data object, display a loading message
  if (!client.id || pageLoading || pageLoadError) {
    if (pageLoadError) {
      return (
        <Box fill justify="center" align="center">
          <Message message={pageLoadError} isError />
        </Box>
      )
    }
    return (
      <Header mini level="5" margin="medium">
        <FormattedMessage {...messages.loading} />
      </Header>
    )
  }
  return (
    <>
      <Grid
        responsive
        rows={['auto', 'auto']}
        columns={['auto']}
        gap="small"
        pad="medium"
        areas={[['header'], ['table']]}
      >
        <Box gridArea="header" direction="column" justify="between" gap="small">
          <Box direction="row-responsive">
            <Box
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              direction="row"
              align="center"
              justify="center"
              round
              border={{ color: 'light-3', size: 'xsmall' }}
            >
              <Text size="small">
                <Link to="/clients">Client List</Link>

                {' / '}

                <Link to={`/clients/summary?id=${client.id}`}>
                  {`${client.first_name.concat(' ', client.last_name)}`}
                </Link>

                {' / Reports'}
              </Text>
            </Box>
          </Box>

          <Box direction="row-responsive" justify="start" align="center" gap="small">
            <Box
              background="light-2"
              justify="center"
              align="center"
              pad="none"
              round="full"
              height="150px"
              width="150px"
            >
              <ClientAvatar
                file={ClientWizardAvatarURL.clientAvatarURLValue.avatar_url}
                size="large"
                type={ClientWizardAvatarURL.clientAvatarURLValue.type}
              />
            </Box>

            <Box direction="row-responsive" align="end" gap="medium">
              <Text size="medium">{`${agencyName} / ${client.first_name} ${client.last_name}`}</Text>
              <Box gap="small">
                <Text size="medium">Check-In Type:</Text>
                <Select
                  style={{ padding: '5px', fontSize: 14 }}
                  size="small"
                  name="filter_by_client_types"
                  id="filter_by_client_types"
                  options={options}
                  labelKey="label"
                  valueKey="value"
                  value={options.find(option => option.value === selectedClientTypes)}
                  onChange={({ option }) => {
                    setselectedClientTypes(option.value);
                  }}
                  searchPlaceholder="Client Types"
                  placeholder="No matches found"
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <>
          <Box gridArea="table" gap="small">
            {(!!rowCount || !!dateTimePickerOpen) && (
              <Box direction="row" justify="between" align="start">
                <Box gap="small">
                  <Box direction="row" align="center" gap="xsmall">
                    <Header mini level="5" margin="none">
                      <FormattedMessage {...messages.datePickerHeader} />
                    </Header>

                    {!dateTimePickerOpen ? (
                      <FormDown
                        size="medium"
                        color="placeholder"
                        onClick={() => setDateTimePickerOpen(true)}
                      />
                    ) : (
                      <FormUp size="medium" onClick={() => setDateTimePickerOpen(false)} />
                    )}
                  </Box>

                  <Collapsible open={dateTimePickerOpen}>
                    <Box align="start" gap="none" margin={{ bottom: 'large' }}>
                      <Box direction="row" pad={{ bottom: 'small' }} gap="small" align="center">
                        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />

                        <StyledButton
                          hoverIndicator
                          primary={false}
                          icon={<Search color={colors.primary} />}
                          onClick={handleDateFilter}
                        />
                      </Box>
                      <Collapsible open={dateFilterApplied}>
                        <StyledButton
                          primary={false}
                          color={colors.primary}
                          label="Clear Filters"
                          onClick={handleDateFilterClear}
                        />
                      </Collapsible>
                    </Box>
                  </Collapsible>
                </Box>
                {clientEvents && clientEvents.length > 0 && (
                  <Box direction="row" justify="end" align="center" gap="small">
                    {downloadState === DOWNLOAD_STATES.downloaded && (
                      <Text
                        color="focus"
                        weight="bold"
                        size="small"
                        margin={{ horizontal: 'small' }}
                      >
                        File has been downloaded.
                      </Text>
                    )}

                    <Button
                      color={colors.primary}
                      disabled={downloadState === DOWNLOAD_STATES.downloading}
                      label="Download PDF"
                      onClick={downloadPDF}
                      reverse
                    ></Button>

                    <Button
                      color={colors.primary}
                      disabled={downloadState === DOWNLOAD_STATES.downloading}
                      label="Download CSV"
                      onClick={downloadCSV}
                      reverse
                    ></Button>
                  </Box>
                )}
              </Box>
            )}

            <Box gridArea="errorMessage">{error && <Message message={error} isError />}</Box>

            <Box fill pad={{ bottom: 'medium' }}>
              <ClientComplianceTable
                loading={loading}
                disabled={loading}
                clientEvents={clientEvents}
                clientTimezone={client.timezone}
                rowCount={rowCount}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
              ></ClientComplianceTable>
            </Box>
          </Box>

          <Box style={{ overflow: 'hidden', height: 0 }}>
            <PrintableComplianceReportPage
              client={client}
              clientEvents={clientEvents}
              agencyName={agencyName}
              clientAvatarURLValue={ClientWizardAvatarURL.clientAvatarURLValue.avatar_url}
              rowCount={rowCount}
              dateTimePickerOpen={dateTimePickerOpen}
              dateRange={dateRange}
              ref={printComplianceReportRef}
            />
          </Box>
        </>
      </Grid>
    </>
  )
}

const StyledButton = styled(Button)`
  margin: 0 !important;
`

export default ClientReportsPage
